import { cssPartial } from "@microsoft/fast-element";
import { designTokens } from "../design-tokens";

// TODO: Should these be tokens too? Should there be a version of these with units?

/**
 * A formula to retrieve the control height.
 * Use this as the value of any CSS property that
 * accepts a pixel size.
 */
export const heightNumber = cssPartial`(${designTokens['space-base-height-multiplier'].token} + ${designTokens['space-density'].token}) * ${designTokens['space-design-unit'].token}`;

/**
 * A formula to retrieve the padding for certain controls.
 */
export const paddingNumber = cssPartial`(${heightNumber} / 2)`;

export const spacingNumber = cssPartial`(${designTokens['space-design-unit'].token} * 5)`;

export const breakpointQuerySmall = cssPartial`screen and (min-width: ${designTokens['space-breakpoint-small'].token}px)`;
export const breakpointQueryMedium = cssPartial`screen and (min-width: ${designTokens['space-breakpoint-medium'].token}px)`;
export const breakpointQueryLarge = cssPartial`screen and (min-width: ${designTokens['space-breakpoint-large'].token}px)`;
export const breakpointQueryExtraLarge = cssPartial`screen and (min-width: ${designTokens['space-breakpoint-extra-large'].token}px)`;
