import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';

export const loaderStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('inline-flex')}

    @keyframes loading {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(.5);
        }
        100% {
            transform: scale(1);
        }
    }

    div {
        width: calc(${designTokens['space-design-unit'].token} * 3px);
        height: calc(${designTokens['space-design-unit'].token} * 3px);
        border-radius: calc(${designTokens['space-design-unit'].token} * 2px);
        background-color: currentColor;
        animation: 1s 0s infinite ease-in-out loading;
    }

    div + div {
        margin-left: calc(${designTokens['space-design-unit'].token} * 1px);
        animation-delay: 125ms;
    }

    div + div + div {
        animation-delay: 250ms;
    }
`;
