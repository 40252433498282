import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { neutralForegroundRest, designTokens } from '../../design-tokens';
import { spacingNumber } from '../../styles/size';

export const radioGroupStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display("flex")} :host {
        align-items: flex-start;
        margin: calc(${designTokens['space-design-unit'].token} * 1px) 0;
        flex-direction: column;
        color: ${neutralForegroundRest};
    }

    .positioning-region {
        display: flex;
        flex-wrap: wrap;
    }

    :host([orientation="vertical"]) .positioning-region {
        flex-direction: column;
    }

    :host([orientation="vertical"]) ::slotted(:not(:first-child)) {
        margin-block-start: calc(${spacingNumber} * 1px);
    }

    :host([orientation="horizontal"]) .positioning-region {
        flex-direction: row;
    }

    :host([orientation="horizontal"]) ::slotted(:not(:first-child)) {
        margin-inline-start: calc(${spacingNumber} * 1px);
    }
`;
