import { html, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenStack } from './stack';

export const stackTemplate: FoundationElementTemplate<ViewTemplate<NatGenStack>> = (
    context,
    definition
) => html`
    <template>
        <slot></slot>
    </template>
`;
