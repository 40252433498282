import { stackGlobalStyles, stackStyles } from './stack.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { stackTemplate } from './stack.template';
import { MediaSizeMixin } from '../../utilities/media-size-observer';
import { attr } from '@microsoft/fast-element';

export type StackAppearance = 'compact' | 'default';
// TODO: Make a generic one to be shared between similar components with directions? Also need reverse versions.
export type StackDirection = 'row' | 'horizontal' | 'column' | 'vertical';

/**
 * @element natgen-stack-layout
 */
export class NatGenStack extends MediaSizeMixin(FoundationElement) {
    @attr appearance: StackAppearance = 'default';
    @attr direction: StackDirection = 'column';

    connectedCallback(): void {
        super.connectedCallback();

        // Due to limitations of the ::slotted selector some layout styles need to live in the light DOM.
        if(!stackGlobalStyles.isAttachedTo(document))
            stackGlobalStyles.addStylesTo(document);
    }
}

export const natGenStackLayout = NatGenStack.compose({
    baseName: 'stack',
    template: stackTemplate,
    styles: stackStyles
});
