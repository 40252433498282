import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { mediaSizeBehavior } from '../../utilities/media-size-observer';

export const stackStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('block')}

    :host {
        display: flex;
        flex-direction: column;
    }

    :host([direction=row]), :host([direction=horizontal]) {
        flex-direction: row;
    }
`;

export const stackGlobalStyles = css`
    natgen-stack[direction=column]:not([appearance="compact"]) > * + *,
    natgen-stack[direction=vertical]:not([appearance="compact"]) > * + * {
        margin-block-start: calc(${designTokens['space-design-unit'].token} * 5px);
    }

    natgen-stack[direction=row]:not([appearance="compact"]) > * + *,
    natgen-stack[direction=horizontal]:not([appearance="compact"]) > * + * {
        margin-inline-start: calc(${designTokens['space-design-unit'].token} * 5px);
    }
`;
