import { html, ViewTemplate, when } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenNavBar } from './nav-bar';

export const navBarTemplate: FoundationElementTemplate<ViewTemplate<NatGenNavBar>> = (
    context,
    definition
) => html<NatGenNavBar>`
    <template role="navigation" ?expanded="${x => x.expand}">
        ${when(x => !x.expand, html`
            <natgen-hamburger-button class="menu-button" aria-label="Main menu">
                <natgen-side-bar class="default" part="side-bar">
                    <slot></slot>
                </natgen-side-bar>
            </natgen-hamburger-button>
        `)}

        <div class="logo" part="logo">
            <slot name="logo"></slot>
        </div>

        ${when(x => x.expand, html`
            <nav part="nav">
                <slot></slot>
            </nav>
        `)}

        <div class="end">
            <slot name="end"></slot>
        </div>
    </template>
`;
