import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { spacingNumber } from '../../styles/size';

export const formStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('flex')}

    :host {
        flex-direction: column;
        align-items: stretch;
    }

    ::slotted(:not(:first-child)) {
        margin-top: calc(${spacingNumber} * 1px);
    }

    :host([media-size="small"]),
    :host([media-size="medium"]),
    :host([media-size="large"]),
    :host([media-size="extra-large"]) {
        align-items: center;
    }

    :host(:not([media-size="extra-small"])) ::slotted([orientation="center"]) {
        text-align: center;
    }

    :host(:not([media-size="extra-small"])) ::slotted([orientation="right"]) {
        text-align: right;
    }
`;

// TODO: A way for form elements to opt-in to either set width or centered items styles declaratively?
// TODO: Should form width be it's own design token?
export const formGlobalStyles = css`
    natgen-form > natgen-form-field > :not([slot]) {
        width: 100%;
    }

    natgen-form:not([media-size="extra-small"]) > natgen-form-field[orientation="center"] > natgen-text-field,
    natgen-form:not([media-size="extra-small"]) > natgen-form-field[orientation="center"] > natgen-select,
    natgen-form:not([media-size="extra-small"]) > natgen-form-field:not([orientation="center"]) {
        width: calc(${designTokens['space-design-unit'].token} * 96px);
    }

    natgen-form:not([media-size="extra-small"]) > natgen-form-field[orientation="center"] > natgen-radio-group {
        align-items: center;
    }
`;
