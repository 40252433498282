import { css, ElementStyles } from '@microsoft/fast-element';
import { ElementDefinitionContext, FoundationElementDefinition } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';
import { heightNumber, paddingNumber } from '../../styles/size';
import { cardStyles } from '../card/card.styles';
import { radioStyles } from '../radio/radio.styles';

export const radioCardStyles: (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => ElementStyles = (
    context: ElementDefinitionContext,
    definition: FoundationElementDefinition
) => css`
    ${cardStyles(context, definition)}
    ${radioStyles(context, definition)}

    :host {
        display: inline-flex;
        align-items: center;

        padding: 0 calc(${paddingNumber} * 1px);
        min-height: calc(${heightNumber} * 1px);

        cursor: pointer;
    }

    .end {
        margin-left: auto;
    }

    .control {
        margin-left: auto;
    }

    .end + .control {
        margin-left: 1em;
    }

    :host(.checked) .control {
        background: transparent;
        border-width: calc(${designTokens['space-stroke-width'].token} * 2px);
        border-color: ${designTokens['color-success'].token};
    }

    .checked-indicator {
        fill: ${designTokens['color-success'].token};
    }
`;
