import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { elevationRule } from '../../styles/elevation';
import { AllCountries } from '../../data/countries';

// From: https://github.com/jackocnr/intl-tel-input
import flags from './flags.png';
import flags2x from './flags@2x.png';

const defaultWidth = 20;
const defaultHeight = 14;

export const flagStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('inline-block')} :host {
        --elevation: 2;
        ${elevationRule}
        background-image: url(${flags});
        background-repeat: no-repeat;
        width: ${defaultWidth.toString()}px;
        height: ${defaultHeight.toString()}px;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        :host {
            background-size: 5652px 15px;
            background-image: url(${flags2x})
        }
    }

    ${AllCountries.reduce<string>(((accumulator, current) => {
        return `${accumulator}
            :host([country-code=${current.iso2}]) {
                ${current.width != defaultWidth ? `width: ${current.width.toString()}px;` : ''};
                ${current.height != defaultHeight ? `height: ${current.height.toString()}px;` : ''};
                background-position: ${current.backgroundPosition} 0px;
            }
        `;
    }), '')}
`;
