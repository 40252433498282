import { css, ElementStyles } from '@microsoft/fast-element';
import { AccordionItemOptions, display, ElementDefinitionContext, focusVisible, forcedColorsStylesheetBehavior, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { neutralForegroundRest, designTokens } from '../../design-tokens';
import { elevationRule } from '../../styles/elevation';
import { heightNumber } from '../../styles/size';

export const accordionItemStyles: FoundationElementTemplate<ElementStyles, AccordionItemOptions> = (
    context,
    definition
) => css`
    ${display("flex")} :host {
        box-sizing: border-box;
        flex-direction: column;
    }
    .region {
        display: none;
        padding: calc(${designTokens['space-design-unit'].token} * 2px);
        font-size: ${designTokens['typography-type-ramp-minus-1-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-minus-1-line-height'].token};
    }
    .heading {
        display: grid;
        grid-template-columns: auto 1fr auto calc(${heightNumber} * 1px);
        position: relative;
    }
    .button {
        appearance: none;
        border: none;
        background: none;
        grid-column: 2;
        grid-row: 1;
        outline: none;
        text-align: left;
        color: ${neutralForegroundRest};
        cursor: pointer;
        font-family: inherit;
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
        min-height: calc(${heightNumber} * 1px);
    }
    .button::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
    :host(:focus-within) {
        --elevation: 4;
        ${elevationRule}
    }
    :host(.expanded) .region {
    display: block;
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 4;
        pointer-events: none;
        fill: currentcolor;
        width: calc(${designTokens['space-design-unit'].token} * 3px);
        position: relative;
    }
    slot[name="collapsed-icon"] {
        display: flex;
    }
    :host(.expanded) slot[name="collapsed-icon"] {
        display: none;
    }
    slot[name="expanded-icon"] {
        display: none;
    }
    :host(.expanded) slot[name="expanded-icon"] {
        display: flex;
    }
    .icon svg {
        width: 100%;
        height: 100%;
    }
    .start {
        display: flex;
        align-items: center;
        padding-inline-start: calc(${designTokens['space-design-unit'].token} * 1px);
        justify-content: center;
        grid-column: 1;
        position: relative;
    }
    .end {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 3;
        position: relative;
    }
`.withBehaviors(
    forcedColorsStylesheetBehavior(
        css`
            .button:${focusVisible}::before {
                forced-color-adjust: none;
                border-color: ${SystemColors.Highlight};
                box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) ${SystemColors.Highlight};
            }
            slot[name="collapsed-icon"],
            slot[name="expanded-icon"] {
                fill: ${SystemColors.ButtonText}
            }
        `
    )
);
