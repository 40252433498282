import { attr, css, html, nullableNumberConverter, observable, Observable } from '@microsoft/fast-element';
import { DesignToken, DesignTokenValue, display, FoundationElement } from '@microsoft/fast-foundation';
import { Palette, Swatch } from '../../color';
import { swatchConverter } from '../../value-converters/swatch-value-converter';
import { backgroundStyles } from './design-system-provider.styles';
import { accentPalette, fillColor, neutralPalette, overrideDesignTokensFromJson, secondaryPalette, designTokens, brand } from '../../design-tokens';
import { typeRampStyles } from '../../styles/typography';

import defaultTheme from '../../design-tokens/default.tokens.json';
import ngicTheme from '../../design-tokens/ngic.tokens.json';
import aieTheme from '../../design-tokens/aie.tokens.json';
import daTheme from '../../design-tokens/directauto.tokens.json';
import encompassTheme from '../../design-tokens/encompass.tokens.json';
import fuiTheme from '../../design-tokens/fui.tokens.json';
import goodsamTheme from '../../design-tokens/goodsam.tokens.json';
import mvicTheme from '../../design-tokens/mvic.tokens.json';
import natgenpremierTheme from '../../design-tokens/natgenpremier.tokens.json';
import njsiTheme from '../../design-tokens/njsi.tokens.json';

function designToken<T>(token: DesignToken<T>) {
    return (source: NatGenDesignSystemProvider, key: string) => {
        source[key + 'Changed'] = function (
            this: NatGenDesignSystemProvider,
            prev: T | undefined,
            next: T | undefined
        ) {
            if (next !== undefined && next !== null) {
                token.setValueFor(this, next as DesignTokenValue<T>);
            } else {
                token.deleteValueFor(this);
            }
        };
    };
}

// TODO: Do we need these enumerated on this provider anymore?
function designTokenKey<T>(tokenKey: string) {
    return (source: NatGenDesignSystemProvider, key: string) => {
        source[key + 'Changed'] = function (
            this: NatGenDesignSystemProvider,
            prev: T | undefined,
            next: T | undefined
        ) {
            if (next !== undefined && next !== null) {
                designTokens[tokenKey].setValueFor(this, next as DesignTokenValue<T>);
            } else {
                designTokens[tokenKey].deleteValueFor(this);
            }
        };
    };
}

/**
 * @element natgen-design-system-provider
 */
export class NatGenDesignSystemProvider extends FoundationElement {
    constructor() {
        super();

        // If fillColor or baseLayerLuminance change, we need to
        // re-evaluate whether we should have paint styles applied
        const subscriber = {
            handleChange: this.noPaintChanged.bind(this),
        };

        Observable.getNotifier(this).subscribe(subscriber, 'fillColor');
        Observable.getNotifier(this).subscribe(subscriber, 'baseLayerLuminance');
    }

    /**
     * Used to instruct the NatGenDesignSystemProvider
     * that it should not set the CSS
     * background-color and color properties
     *
     * @remarks
     * HTML boolean attribute: no-paint
     */
    @attr({ attribute: 'no-paint', mode: 'boolean' })
    public noPaint = false;
    private noPaintChanged() {
        if (!this.noPaint && (this.fillColor !== void 0 || this.baseLayerLuminance !== void 0))
            this.$fastController.addStyles(backgroundStyles);
        else
            this.$fastController.removeStyles(backgroundStyles);
    }

    @attr({ attribute: "use-default-typography", mode: "boolean" })
    public useDefaultTypography: boolean = false;
    private useDefaultTypographyChanged() {
        if (this.useDefaultTypography) {
            typeRampStyles.addStylesTo(document);
        } else {
            typeRampStyles.removeStylesFrom(document);
        }
    }

    @attr
    public brand: string = "ngic";
    private brandChanged() {
        // TODO: Better reset which doesn't clobber manual overrides?
        Object.keys(designTokens).forEach(k => designTokens[k].deleteValueFor(this));

        brand.setValueFor(this, this.brand);

        switch(this.brand)
        {
            case 'ngic':
                overrideDesignTokensFromJson(ngicTheme, this);
                break;
            case 'aie':
                overrideDesignTokensFromJson(aieTheme, this);
                break;
            case 'directauto':
                overrideDesignTokensFromJson(daTheme, this)
                break;
            case 'encompass':
                overrideDesignTokensFromJson(encompassTheme, this)
                break;
            case 'goodsam':
                overrideDesignTokensFromJson(goodsamTheme, this)
                break;
            case 'fui':
                overrideDesignTokensFromJson(fuiTheme, this);
                break;
            case 'mvic':
                overrideDesignTokensFromJson(mvicTheme, this);
                break;
            case 'natgenpremier':
                overrideDesignTokensFromJson(natgenpremierTheme, this);
                break;
            case 'njsi':
                overrideDesignTokensFromJson(njsiTheme, this);
                break;
            default:
                overrideDesignTokensFromJson(defaultTheme, this);
                break;
        }
    }

    /**
     * The {@link https://www.w3.org/WAI/GL/wiki/Relative_luminance#:~:text=WCAG%20definition%20of%20relative%20luminance,and%201%20for%20lightest%20white|relative luminance} of the base layer of the application.
     *
     * @remarks
     * When set to a number between 0 and 1, this value controls the luminosity of the neutral layers.
     *
     * HTML attribute: base-layer-luminance
     *
     * CSS custom property: N/A
     */
    @attr({
        attribute: "base-layer-luminance",
        converter: nullableNumberConverter,
    })
    @designTokenKey('color-base-layer-luminance')
    public baseLayerLuminance: number;

    /**
     * Define design system property attributes
     * @remarks
     * HTML attribute: background-color
     *
     * CSS custom property: --fill-color
     */
    @attr({
        attribute: 'background-color',
        converter: swatchConverter,
    })
    @designToken(fillColor)
    public fillColor: Swatch;

    /**
     * Set the neutral color
     * @remarks
     * HTML attribute: neutral-color
     */
    @attr({
        attribute: "neutral-base-color",
        converter: swatchConverter,
        mode: "fromView",
    })
    @designTokenKey('color-neutral')
    public neutralBaseColor?: Swatch;

    /**
     * Set the accent color
     * @remarks
     * HTML attribute: accent-color
     */
    @attr({
        attribute: "accent-base-color",
        converter: swatchConverter,
        mode: "fromView",
    })
    @designTokenKey('color-accent')
    public accentBaseColor?: Swatch;

    /**
     * Set the accent color
     * @remarks
     * HTML attribute: accent-color
     */
    @attr({
        attribute: "secondary-base-color",
        converter: swatchConverter,
        mode: "fromView",
    })
    @designTokenKey('color-secondary')
    public secondaryBaseColor?: Swatch;

    /**
     * This color is used to grab the user's attention.
     */
    @attr({
        attribute: 'error-color',
        converter: swatchConverter,
    })
    @designTokenKey('color-error')
    public errorColor: string;

    /**
     * This color is used to grab the user's attention.
     */
    @attr({
        attribute: 'success-color',
        converter: swatchConverter,
    })
    @designTokenKey('color-success')
    public successColor: string;

    /**
     * Defines the palette that all neutral color recipes are derived from.
     * This is an array for hexadecimal color strings ordered from light to dark.
     */
    @observable
    @designToken(neutralPalette)
    public neutralPalette: Palette;

    /**
     * Defines the palette that all accent color recipes are derived from.
     * This is an array for hexadecimal color strings ordered from light to dark.
     */
    @observable
    @designToken(accentPalette)
    public accentPalette: Palette;

    /**
     * Defines the palette that all secondary accent color recipes are derived from.
     * This is an array for hexadecimal color strings ordered from light to dark.
     */
    @observable
    @designToken(secondaryPalette)
    public secondaryPalette: Palette;

    /**
     *
     * The density offset, used with designUnit to calculate height and spacing.
     *
     * @remarks
     * HTML attribute: density
     *
     * CSS custom property: --density
     */
    @attr({
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-density')
    public density: number;

    /**
     * The grid-unit that UI dimensions are derived from in pixels.
     *
     * @remarks
     * HTML attribute: design-unit
     *
     * CSS custom property: --space-design-unit
     */
    @attr({
        attribute: "design-unit",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-design-unit')
    public designUnit: number;

    /**
     * The number of designUnits used for component height at the base density.
     *
     * @remarks
     * HTML attribute: base-height-multiplier
     *
     * CSS custom property: --base-height-multiplier
     */
    @attr({
        attribute: "base-height-multiplier",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-base-height-multiplier')
    public baseHeightMultiplier: number;

    /**
     * The number of designUnits used for horizontal spacing at the base density.
     *
     * @remarks
     * HTML attribute: base-horizontal-spacing-multiplier
     *
     * CSS custom property: --base-horizontal-spacing-multiplier
     */
    @attr({
        attribute: "base-horizontal-spacing-multiplier",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-base-horizontal-spacing-multiplier')
    public baseHorizontalSpacingMultiplier: number;

    /**
     * The corner radius applied to controls.
     *
     * @remarks
     * HTML attribute: corner-radius
     *
     * CSS custom property: --corner-radius
     */
    @attr({
        attribute: "corner-radius",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-corner-radius')
    public cornerRadius: number;

    /**
     * The corner radius applied to inputs.
     *
     * @remarks
     * HTML attribute: input-corner-radius
     *
     * CSS custom property: --input-corner-radius
     */
    @attr({
        attribute: "input-corner-radius",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-input-corner-radius')
    public inputCornerRadius: number;

    /**
     * The corner radius applied to buttons.
     *
     * @remarks
     * HTML attribute: button-corner-radius
     *
     * CSS custom property: --button-corner-radius
     */
    @attr({
        attribute: "button-corner-radius",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-button-corner-radius')
    public buttonCornerRadius: number;

    /**
     * The width of the standard stroke applied to stroke components in pixels.
     *
     * @remarks
     * HTML attribute: stroke-width
     *
     * CSS custom property: --stroke-width
     */
    @attr({
        attribute: "stroke-width",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-stroke-width')
    public strokeWidth: number;

    /**
     * The width of the standard focus stroke in pixels.
     *
     * @remarks
     * HTML attribute: focus-stroke-width
     *
     * CSS custom property: --focus-stroke-width
     */
    @attr({
        attribute: "focus-stroke-width",
        converter: nullableNumberConverter,
    })
    @designTokenKey('space-focus-stroke-width')
    public focusStrokeWidth: number;

    /**
     * The opacity of a disabled control.
     *
     * @remarks
     * HTML attribute: disabled-opacity
     *
     * CSS custom property: --disabled-opacity
     */
    @attr({
        attribute: "disabled-opacity",
        converter: nullableNumberConverter,
    })
    @designTokenKey('color-disabled-opacity')
    public disabledOpacity: number;

    /**
    * The font-size two steps below the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-minus-3-font-size
    *
    * CSS custom property: --typography-type-ramp-minus-3-font-size
    */
   @attr({
       attribute: "type-ramp-minus-3-font-size",
   })
   @designTokenKey('typography-type-ramp-minus-3-font-size')
   public typeRampMinus3FontSize: string;

   /**
    * The line-height two steps below the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-minus-3-line-height
    *
    * CSS custom property: --typography-type-ramp-minus-3-line-height
    */
   @attr({
       attribute: "type-ramp-minus-3-line-height",
   })
   @designTokenKey('typography-type-ramp-minus-3-line-height')
   public typeRampMinus3LineHeight: string;

    /**
    * The font-size two steps below the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-minus-2-font-size
    *
    * CSS custom property: --typography-type-ramp-minus-2-font-size
    */
   @attr({
       attribute: "type-ramp-minus-2-font-size",
   })
   @designTokenKey('typography-type-ramp-minus-2-font-size')
   public typeRampMinus2FontSize: string;

   /**
    * The line-height two steps below the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-minus-2-line-height
    *
    * CSS custom property: --typography-type-ramp-minus-2-line-height
    */
   @attr({
       attribute: "type-ramp-minus-2-line-height",
   })
   @designTokenKey('typography-type-ramp-minus-2-line-height')
   public typeRampMinus2LineHeight: string;

   /**
    * The font-size one step below the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-minus-1-font-size
    *
    * CSS custom property: --typography-type-ramp-minus-1-font-size
    */
   @attr({
       attribute: "type-ramp-minus-1-font-size",
   })
   @designTokenKey('typography-type-ramp-minus-1-font-size')
   public typeRampMinus1FontSize: string;

   /**
    * The line-height one step below the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-minus-1-line-height
    *
    * CSS custom property: --typography-type-ramp-minus-1-line-height
    */
   @attr({
       attribute: "type-ramp-minus-1-line-height",
   })
   @designTokenKey('typography-type-ramp-minus-1-line-height')
   public typeRampMinus1LineHeight: string;

   /**
    * The base font-size of the relative type-ramp scale
    *
    * @remarks
    * HTML attribute: type-ramp-base-font-size
    *
    * CSS custom property: --typography-type-ramp-base-font-size
    */
   @attr({
       attribute: "type-ramp-base-font-size",
   })
   @designTokenKey('typography-type-ramp-base-font-size')
   public typeRampBaseFontSize: string;

   /**
    * The base line-height of the relative type-ramp scale
    *
    * @remarks
    * HTML attribute: type-ramp-base-line-height
    *
    * CSS custom property: --typography-type-ramp-base-line-height
    */
   @attr({
       attribute: "type-ramp-base-line-height",
   })
   @designTokenKey('typography-type-ramp-base-line-height')
   public typeRampBaseLineHeight: string;

   /**
    * The font-size one step above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-1-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-1-font-size
    */
   @attr({
       attribute: "type-ramp-plus-1-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-1-font-size')
   public typeRampPlus1FontSize: string;

   /**
    * The line-height one step above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-1-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-1-line-height
    */
   @attr({
       attribute: "type-ramp-plus-1-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-1-line-height')
   public typeRampPlus1LineHeight: string;

   /**
    * The font-size two steps above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-2-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-2-font-size
    */
   @attr({
       attribute: "type-ramp-plus-2-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-2-font-size')
   public typeRampPlus2FontSize: string;

   /**
    * The line-height two steps above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-2-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-2-line-height
    */
   @attr({
       attribute: "type-ramp-plus-2-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-2-line-height')
   public typeRampPlus2LineHeight: string;

   /**
    * The font-size three steps above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-3-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-3-font-size
    */
   @attr({
       attribute: "type-ramp-plus-3-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-3-font-size')
   public typeRampPlus3FontSize: string;

   /**
    * The line-height three steps above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-3-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-3-line-height
    */
   @attr({
       attribute: "type-ramp-plus-3-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-3-line-height')
   public typeRampPlus3LineHeight: string;

   /**
    * The font-size four steps above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-4-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-4-font-size
    */
   @attr({
       attribute: "type-ramp-plus-4-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-4-font-size')
   public typeRampPlus4FontSize: string;

   /**
    * The line-height four steps above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-4-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-4-line-height
    */
   @attr({
       attribute: "type-ramp-plus-4-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-4-line-height')
   public typeRampPlus4LineHeight: string;

   /**
    * The font-size five steps above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-5-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-5-font-size
    */
   @attr({
       attribute: "type-ramp-plus-5-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-5-font-size')
   public typeRampPlus5FontSize: string;

   /**
    * The line-height five steps above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-5-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-5-line-height
    */
   @attr({
       attribute: "type-ramp-plus-5-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-5-line-height')
   public typeRampPlus5LineHeight: string;

   /**
    * The font-size six steps above the base font-size
    *
    * @remarks
    * HTML attribute: type-ramp-plus-6-font-size
    *
    * CSS custom property: --typography-type-ramp-plus-6-font-size
    */
   @attr({
       attribute: "type-ramp-plus-6-font-size",
   })
   @designTokenKey('typography-type-ramp-plus-6-font-size')
   public typeRampPlus6FontSize: string;

   /**
    * The line-height six steps above the base line-height
    *
    * @remarks
    * HTML attribute: type-ramp-plus-6-line-height
    *
    * CSS custom property: --typography-type-ramp-plus-6-line-height
    */
   @attr({
       attribute: "type-ramp-plus-6-line-height",
   })
   @designTokenKey('typography-type-ramp-plus-6-line-height')
   public typeRampPlus6LineHeight: string;
}

/**
 * A function that returns a {@link DesignSystemProvider} registration for configuring the component with a DesignSystem.
 * @public
 * @remarks
 * Generates HTML Element: `<natgen-design-system-provider>`
 */
export const natGenDesignSystemProvider = NatGenDesignSystemProvider.compose({
    baseName: 'design-system-provider',
    template: html`<slot></slot>`,
    styles: css`${display('block')}`
});
