import { directionByIsDark } from "../utilities/is-dark";
import { RelativeLuminance } from "./luminance";
import { Palette } from "./palette";
import { Swatch, SwatchRGB, black, white } from "./swatch";

export enum ContrastTarget {
    Normal = 4.5,
    Large = 7,
    Text = 10
}

export class Luminance {
    static getContrast(a: RelativeLuminance, b: RelativeLuminance): number {
        const L1 = a.relativeLuminance > b.relativeLuminance ? a : b;
        const L2 = a.relativeLuminance > b.relativeLuminance ? b : a;

        return (L1.relativeLuminance + 0.05) / (L2.relativeLuminance + 0.05);
    }

    static toSwatch(luminance: number) {
        return new SwatchRGB(luminance, luminance, luminance);
    }
}

export interface InteractiveSet<T> {
    rest: T;
    hover: T;
    active: T;
    focus: T;
}

export class InteractiveSwatchSet implements InteractiveSet<Swatch> {
    rest: Swatch;
    hover: Swatch;
    active: Swatch;
    focus: Swatch;

    static create(
        palette: Palette,
        reference: Swatch,
        restDelta: number,
        hoverDelta: number,
        activeDelta: number,
        focusDelta: number,
        direction?: -1 | 1 | null
    ): InteractiveSwatchSet {
        const referenceIndex = palette.closestIndexOf(reference);
        if (direction === null || direction === void 0) {
            direction = directionByIsDark(reference);
        }

        return {
            rest: palette.get(referenceIndex + direction * restDelta),
            hover: palette.get(referenceIndex + direction * hoverDelta),
            active: palette.get(referenceIndex + direction * activeDelta),
            focus: palette.get(referenceIndex + direction * focusDelta),
        };
    }

    static createFromIdealColor(
        palette: Palette,
        idealColor: Swatch,
        reference: Swatch,
        minContrast: number,
        restDelta: number,
        hoverDelta: number,
        activeDelta: number,
        focusDelta: number
    ): InteractiveSwatchSet {
        const idealIndex = palette.closestIndexOf(idealColor);
        const direction = directionByIsDark(reference);
        const startIndex =
            idealIndex +
            (direction === 1
                ? Math.min(restDelta, hoverDelta)
                : Math.max(direction * restDelta, direction * hoverDelta));
        const accessibleSwatch = palette.colorContrast(
            reference,
            minContrast,
            startIndex,
            direction
        );
        const accessibleIndex1 = palette.closestIndexOf(accessibleSwatch);
        const accessibleIndex2 =
            accessibleIndex1 + direction * Math.abs(restDelta - hoverDelta);
        const indexOneIsRestState =
            direction === 1
                ? restDelta < hoverDelta
                : direction * restDelta > direction * hoverDelta;

        let restIndex: number;
        let hoverIndex: number;

        if (indexOneIsRestState) {
            restIndex = accessibleIndex1;
            hoverIndex = accessibleIndex2;
        } else {
            restIndex = accessibleIndex2;
            hoverIndex = accessibleIndex1;
        }

        return {
            rest: palette.get(restIndex),
            hover: palette.get(hoverIndex),
            active: palette.get(restIndex + direction * activeDelta),
            focus: palette.get(restIndex + direction * focusDelta),
        };
    }

    static createFromContrast(
        palette: Palette,
        reference: Swatch,
        baseContrast: number,
        restDelta: number,
        hoverDelta: number,
        activeDelta: number,
        focusDelta: number,
        direction?: -1 | 1 | null
    ) {
        if (direction === null || direction === void 0)
            direction = directionByIsDark(reference);

        const baseIndex = palette.closestIndexOf(
            palette.colorContrast(reference, baseContrast)
        );

        return {
            rest: palette.get(baseIndex + direction * restDelta),
            hover: palette.get(baseIndex + direction * hoverDelta),
            active: palette.get(baseIndex + direction * activeDelta),
            focus: palette.get(baseIndex + direction * focusDelta),
        };
    }
}

export function calculateDeltaSwatch(palette: Palette, reference: Swatch, delta: number) {
    return palette.get(
        palette.closestIndexOf(reference) + directionByIsDark(reference) * delta
    );
}

export function calculateNeutralLayer(palette: Palette, baseLayerLuminance: number, delta: number = 0): Swatch {
    const neutralLayer1Index = palette.closestIndexOf(Luminance.toSwatch(baseLayerLuminance));
    return palette.get(neutralLayer1Index + delta);
}

export function calculateFocusStrokeOuter(palette: Palette, reference: Swatch): Swatch {
    return palette.colorContrast(reference, 5.5);
}

export function calculateFocusStrokeInner(palette: Palette, reference: Swatch, focusColor: Swatch): Swatch {
    return palette.colorContrast(
        focusColor,
        3.5,
        palette.closestIndexOf(palette.source),
        (directionByIsDark(reference) * -1) as 1 | -1
    );
}

export function calculateForegroundOnColor(reference: Swatch, contrastTarget: number) {
    return reference.contrast(white) >= contrastTarget ? white : black;
}
