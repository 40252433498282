import { attr, DOM } from "@microsoft/fast-element";
import { FoundationElement } from "@microsoft/fast-foundation";
import { createPopper, Instance, OptionsGeneric, Placement } from '@popperjs/core';
import { jsonConverter } from "../../value-converters/json-value-converter";
import { popperStyles } from "./popper.styles";
import { popperTemplate } from "./popper.template";

/**
 * @element natgen-popper
 */
export class NatGenPopper extends FoundationElement {
    protected popper: Instance;
    protected target: HTMLElement;

    @attr
    selector: string;
    selectorChanged(oldValue: string, newValue: string): void {
        DOM.queueUpdate(() => {
            if (newValue !== oldValue) {
                var newTarget = (this.getRootNode() as Element).querySelector(this.selector);

                if(newTarget) {
                    if (this.target)
                        this.target.removeEventListener(this.event, this.toggle.bind(this));

                    this.target = newTarget as HTMLElement;
                }
                else
                    return;

                if (this.popper)
                    this.popper.destroy();

                this.target.addEventListener(this.event, this.toggle.bind(this));
                this.popper = createPopper(this.target, this, this.buildOptions());
            }
        });
    }

    @attr({ mode: 'boolean' })
    show: boolean = false;

    @attr
    event: string = 'click';

    // @attr({ mode: 'boolean' })
    // arrow: boolean = false;
    // arrowChanged(): void {
    //     this.update();
    // }

    // arrowElement: HTMLDivElement;

    // @attr
    // arrowPadding: number = 0;
    // arrowPaddingChanged(): void {
    //     this.update();
    // }

    @attr
    placement: Placement = 'bottom';
    placementChanged(): void {
        this.update();
    }

    @attr({ converter: jsonConverter })
    offset: [number, number];
    offsetChanged(): void {
        this.update();
    }

    @attr({ mode: 'boolean' })
    flip: boolean = false;
    flipChanged(): void {
        this.update();
    }

    @attr({ mode: 'boolean', attribute: 'match-target-width' })
    matchTargetWidth: boolean = false;
    matchTargetWidthChanged(): void {
        this.update();
    }

    protected buildOptions(): Partial<OptionsGeneric<any>> {
        var options = {
            placement: this.placement,
            modifiers: [
                {
                    name: 'matchTargetWidth',
                    enabled: this.matchTargetWidth,
                    phase: 'beforeWrite',
                    requires: ['computeStyles'],
                    fn: ({ state }) => {
                        state.styles.popper.width = state.rects.reference.width + 'px';
                    }
                },
                // {
                //     name: 'arrow',
                //     options: {
                //         padding: 5,
                //         element: this.arrowElement
                //     }
                // },
                {
                    name: 'flip',
                    enabled: this.flip
                },
                {
                    name: 'offset',
                    options: {
                        offset: this.offset || [0, 0]
                    }
                }
            ]
        };

        return options;
    }

    protected update(): void {
        if(this.popper && this.isConnected)
            this.popper.setOptions(this.buildOptions());
    }

    toggle(e: MouseEvent = null) {
        this.show = !this.show;
        DOM.queueUpdate(() => this?.popper?.update());
        //e?.stopImmediatePropagation();
    }

    protected onDocumentClick(e: MouseEvent) {
        if(this.show && !e.composedPath().includes(this) && !e.composedPath().includes(this.target)) {
            this.show = false;
            DOM.queueUpdate(() => this?.popper?.update());
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('click', this.onDocumentClick.bind(this));
        this.update();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('click', this.onDocumentClick.bind(this));

        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
    }
}

export const natGenPopper = NatGenPopper.compose({
    baseName: 'popper',
    template: popperTemplate,
    styles: popperStyles
});
