import { cssPartial } from '@microsoft/fast-element'
import { designTokens } from '../design-tokens';

export const ambientShadow = cssPartial`0 0 calc((var(--elevation) * 0.225px) + 2px) rgba(0, 0, 0, calc(.11 * (2 - ${designTokens['color-base-layer-luminance'].token})))`;
export const directionalShadow = cssPartial`0 calc(var(--elevation) * 0.4px) calc((var(--elevation) * 0.9px)) rgba(0, 0, 0, calc(.13 * (2 - ${designTokens['color-base-layer-luminance'].token})))`;
export const elevation = cssPartial`${ambientShadow}, ${directionalShadow}`;

/**
* Applies the box-shadow CSS rule set to the elevation formula.
* Control this formula with the --elevation CSS Custom Property
* by setting --elevation to a number.
*/
export const elevationRule = cssPartial`box-shadow: ${elevation};`;
