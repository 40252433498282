import { attr, observable } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { formFieldStyles } from './form-field.styles';
import { formFieldTemplate } from './form-field.template';

export class NatGenFormField extends FoundationElement {
    @attr label: string;
    @attr orientation: 'center' | 'left' | 'right' = 'left';
    // TODO: Allow for control of tooltip placement?
    @observable slottedTooltipNodes: Node[];
}

export const natGenFormField = NatGenFormField.compose({
    baseName: 'form-field',
    template: formFieldTemplate,
    styles: formFieldStyles
});
