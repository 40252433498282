import { hamburgerButtonTemplate } from './hamburger-button.template';
import { hamburgerButtonStyles } from './hamburger-button.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { observable } from '@microsoft/fast-element';

/**
 * @element natgen-hamburger-button
 */
export class NatGenHamburgerButton extends FoundationElement {
    @observable opened: boolean = false;

    toggleOpened(e: Event, onlyControl = false) {
        if (!onlyControl || (e.target as HTMLElement).classList.contains('overlay'))
            this.opened = !this.opened;

        return true;
    }
}

export const natGenHamburgerButton = NatGenHamburgerButton.compose({
    baseName: 'hamburger-button',
    template: hamburgerButtonTemplate,
    styles: hamburgerButtonStyles
});
