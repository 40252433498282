import { html, slotted, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate, RadioOptions } from '@microsoft/fast-foundation';
import { NatGenRadioCard } from './radio-card';

// TODO: Use checked indicator from RadioOptions?
export const radioCardTemplate: FoundationElementTemplate<ViewTemplate<NatGenRadioCard>, RadioOptions> = (
    context,
    definition
) => html<NatGenRadioCard>`
    <template
        role="radio"
        class="${x => (x.checked ? "checked" : "")} ${x =>
            x.readOnly ? "readonly" : ""}"
        aria-checked="${x => x.checked}"
        aria-required="${x => x.required}"
        aria-disabled="${x => x.disabled}"
        aria-readonly="${x => x.readOnly}"
        @keypress="${(x, c) => x.keypressHandler(c.event as KeyboardEvent)}"
        @click="${(x, c) => x.clickHandler(c.event as MouseEvent)}"
    >
        <span class="start">
            <slot name="start"></slot>
        </span>
        <label
            part="label"
            class="${x =>
                x.defaultSlottedNodes && x.defaultSlottedNodes.length
                    ? "label"
                    : "label label__hidden"}"
        >
            <slot ${slotted("defaultSlottedNodes")}></slot>
        </label>
        <span class="end">
            <slot name="end"></slot>
        </span>

        <div part="control" class="control">
            <slot name="checked-indicator">
                <div part="checked-indicator" class="checked-indicator">
                    <svg
                        aria-hidden="true"
                        part="checked-indicator"
                        class="checked-indicator"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.143 12.6697L15.235 4.5L16.8 5.90363L8.23812 15.7667L3.80005 11.2556L5.27591 9.7555L8.143 12.6697Z"
                        />
                    </svg>
                </div>
            </slot>
        </div>
    </template>
`;
