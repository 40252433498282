import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { accentForegroundRest, designTokens } from '../../design-tokens';
import { paddingNumber } from '../../styles/size';

export const formFieldStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('block')} :host {
        width: 100%;
        text-align: center;
    }

    :host([orientation="left"]) {
        text-align: left;
    }

    :host([orientation="right"]) {
        text-align: right;
    }

    .tooltip {
        vertical-align: middle;
        color: ${accentForegroundRest};
    }

    natgen-popper {
        z-index: 10;
        text-align: left;
    }

    natgen-popper > natgen-card {
        padding: calc(${paddingNumber} * 1px);
    }
`;
