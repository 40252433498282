import { navBarTemplate } from './nav-bar.template';
import { navBarStyles } from './nav-bar.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { observable } from '@microsoft/fast-element';
import { designTokens } from '../../design-tokens';

const navBarResizeObserver: ResizeObserver = new ResizeObserver((entries) => {
    for(const entry of entries) {
        if(entry.borderBoxSize) {
            (entry.target as NatGenNavBar).expand = entry.borderBoxSize[0].inlineSize > designTokens['space-breakpoint-large'].getValueFor(entry.target as HTMLElement)
        }
    }
});

/**
 * @element natgen-nav-bar
 */
export class NatGenNavBar extends FoundationElement {
    @observable expand = true;

    connectedCallback() {
        super.connectedCallback();

        navBarResizeObserver.observe(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        navBarResizeObserver.unobserve(this);
    }
}

export const natGenNavbar = NatGenNavBar.compose({
    baseName: 'nav-bar',
    template: navBarTemplate,
    styles: navBarStyles
});
