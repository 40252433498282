import { Card, cardTemplate } from '@microsoft/fast-foundation';
import { cardStyles } from './card.styles';

/**
 * @element natgen-card
 */
export class NatGenCard extends Card {

}

export const natGenCard = NatGenCard.compose({
    baseName: 'card',
    baseClass: Card,
    template: cardTemplate,
    styles: cardStyles
});
