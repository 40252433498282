import { attr, css, html, when } from '@microsoft/fast-element';
import { FoundationElement, display } from '@microsoft/fast-foundation';
import { brand, designTokens } from '../../design-tokens';

export class NatGenLogo extends FoundationElement {
    @attr
    brand: string;

    getLogoPath() : string {
        var currentBrand = this.brand || brand.getValueFor(this);

        return `${process.env.ASSET_PATH ? process.env.ASSET_PATH : ''}branding/${designTokens[`branding-logos-${currentBrand}-image`].getValueFor(this)}`;
    }

    hasDefinedHeight() : boolean {
        var currentBrand = this.brand || brand.getValueFor(this);

        return `branding-logos-${currentBrand}-size` in designTokens;
    }

    getLogoHeightStyle() : string {
        var currentBrand = this.brand || brand.getValueFor(this);

        return this.hasDefinedHeight() ?
            `height: ${(designTokens[`branding-logos-${currentBrand}-size`]?.getValueFor(this) || 10) * designTokens['space-design-unit'].getValueFor(this)}px;` : '';
    }

    hasDefinedPadding() : boolean {
        var currentBrand = this.brand || brand.getValueFor(this);

        return `branding-logos-${currentBrand}-padding` in designTokens;
    }

    getLogoPaddingStyle() : string {
        var currentBrand = this.brand || brand.getValueFor(this);

        return this.hasDefinedPadding() ?
            `margin: ${(designTokens[`branding-logos-${currentBrand}-padding`]?.getValueFor(this) || 0) * designTokens['space-design-unit'].getValueFor(this)}px;` : '';
    }
}

export const natGenLogo = NatGenLogo.compose({
    baseName: 'logo',
    template: html<NatGenLogo>`
        <img style="${x => x.getLogoHeightStyle()} ${x => x.getLogoPaddingStyle()}" src="${x => x.getLogoPath()}" />
    `,
    styles: css`
        ${display('block')}
    `
});
