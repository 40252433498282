import { css, ElementStyles } from "@microsoft/fast-element";
import { display, focusVisible, forcedColorsStylesheetBehavior, FoundationElementTemplate, SwitchOptions } from "@microsoft/fast-foundation";
import { SystemColors } from "@microsoft/fast-web-utilities";
import { accentFillActive, accentFillHover, accentFillRest, fillColor, focusStrokeOuter, foregroundOnAccentActive, foregroundOnAccentHover, neutralFillRest, neutralForegroundRest, neutralStrokeHover, neutralStrokeRest, designTokens } from "../../design-tokens";
import { DirectionalStyleSheetBehavior } from '../../styles/direction'

/**
 * Styles for Switch
 * @public
 */
export const switchStyles: FoundationElementTemplate<ElementStyles, SwitchOptions> = (
    context,
    definition
) =>
    css`
    ${display("inline-flex")} :host {
        align-items: center;
        outline: none;
        font-family: ${designTokens['typography-font-family'].token};
        margin: calc(${designTokens['space-design-unit'].token} * 1px) 0;
        ${
            ""
        } user-select: none;
    }

    .switch {
        position: relative;
        outline: none;
        box-sizing: border-box;
        width: calc(${designTokens['space-design-unit'].token} * 11px);
        height: calc((${designTokens['space-design-unit'].token}) * 5px);
        background: ${neutralStrokeRest};
        border-radius: 30px;
        border: none;
    }

    .switch:hover {
        background: ${neutralStrokeHover};
        cursor: pointer;
    }

    .switch:active {
        background: ${accentFillActive};
    }

    :host(:${focusVisible}) .switch {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${focusStrokeOuter};
    }

    .checked-indicator {
        position: absolute;
        top: -4px;
        bottom: 0px;
        height: calc((${designTokens['space-design-unit'].token}) * 7px);
        width: calc((${designTokens['space-design-unit'].token}) * 7px);
        background: ${neutralFillRest};
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
    }

    .label {
        color: ${neutralForegroundRest};

        ${
            ""
        } margin-inline-end: calc(${designTokens['space-design-unit'].token} * 1px + 1px);
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
        cursor: pointer;
    }

    :host([aria-checked="true"]) .checked-indicator {
        background: ${accentFillRest};
    }

    :host([aria-checked="true"]) .switch {
        background: ${accentFillActive};
    }

    :host([aria-checked="true"] .switch:hover {
        background: ${accentFillHover};
    }

    :host([aria-checked="true"] .switch:hover .checked-indicator {
        background: ${accentFillHover};
    }

    .checked-indicator {
        background: ${accentFillRest};
    }

    :host([aria-checked="true"] .switch:active {
        background: ${accentFillActive};
    }

    :host([aria-checked="true"] .switch:active .checked-indicator {
        background: ${foregroundOnAccentActive};
    }

    :host([aria-checked="true"]:${focusVisible} .switch {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${focusStrokeOuter};
    }

    // :host([aria-checked="true"]:not([disabled])) .switch:hover {
    //     background: ${accentFillHover};
    //     border-color: ${accentFillHover};
    // }

    // :host([aria-checked="true"]:not([disabled])) .switch:hover .checked-indicator {
    //     background: ${foregroundOnAccentHover};
    // }

    // :host([aria-checked="true"]:not([disabled])) .switch:active {
    //     background: ${accentFillActive};
    //     border-color: ${accentFillActive};
    // }

    // :host([aria-checked="true"]:not([disabled])) .switch:active .checked-indicator {
    //     background: ${foregroundOnAccentActive};
    // }

    :host([aria-checked="true"]:${focusVisible}:not([disabled])) .switch {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${focusStrokeOuter};
    }
`.withBehaviors(
        forcedColorsStylesheetBehavior(
            css`
            .checked-indicator,
            .switch:active .checked-indicator {
                forced-color-adjust: none;
                background: ${SystemColors.FieldText};
            }
            .switch {
                forced-color-adjust: none;
                background: ${SystemColors.Field};
            }
            :host(:not([disabled])) .switch:hover {
                background: ${SystemColors.HighlightText};
            }
            :host([aria-checked="true"]) .switch {
                background: ${SystemColors.Highlight};
            }
            :host([aria-checked="true"]:not([disabled])) .switch:hover,
            :host(:not([disabled])) .switch:active {
                background: ${SystemColors.HighlightText};
            }
            :host([aria-checked="true"]) .checked-indicator {
                background: ${SystemColors.HighlightText};
            }
            :host([aria-checked="true"]:not([disabled])) .switch:hover .checked-indicator {
                background: ${SystemColors.Highlight};
            }
            :host([disabled]) {
                opacity: 1;
            }
            :host(:${focusVisible}) .switch {
                box-shadow: 0 0 0 2px ${SystemColors.Field}, 0 0 0 4px ${SystemColors.FieldText};
            }
            :host([aria-checked="true"]:${focusVisible}:not([disabled])) .switch {
                box-shadow: 0 0 0 2px ${SystemColors.Field}, 0 0 0 4px ${SystemColors.FieldText};
            }
            :host([disabled]) .checked-indicator {
                background: ${SystemColors.GrayText};
            }
            :host([disabled]) .switch {
                background: ${SystemColors.Field};
            }
        `
        ),
        new DirectionalStyleSheetBehavior(
            css`
                .checked-indicator {
                    left: 0px;
                    right: calc(${designTokens['space-design-unit'].token} * 5px);
                }

                :host([aria-checked="true"]) .checked-indicator {
                    left: calc(${designTokens['space-design-unit'].token} * 5px);
                    right: 0px;
                }
            `,
            css`
                .checked-indicator {
                    right: 0px;
                    left: calc(${designTokens['space-design-unit'].token} * 5px);
                }

                :host([aria-checked="true"]) .checked-indicator {
                    right: calc(${designTokens['space-design-unit'].token} * 5px);
                    left: 0px;
                }
            `
        )
    );
