import { html, repeat, ViewTemplate } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenPalette } from './palette';

export const paletteTemplate: FoundationElementTemplate<ViewTemplate<NatGenPalette>> = (
    context,
    definition
) => html`
    <template>
        ${repeat(x => x.palette, html<string>`
            <div>
                <span class="swatch" style="background-color: ${x => x};"></span>
                <span class="${(x, c) => x == c.parent.target ? 'target' : ''}">${x => x}</span>
            </div>
        `)}
    </template>
`;
