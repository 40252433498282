import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { neutralForegroundRest, neutralLayer1, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber, paddingNumber } from '../../styles/size';

export const navBarStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('flex')}

    :host {
        align-items: center;
        justify-content: center;

        padding: 0 calc(${paddingNumber} * 1px);
        height: calc(${heightNumber} * 2px);

        background: ${neutralLayer1};
        color: ${neutralForegroundRest};
    }

    natgen-hamburger-button::part(overlay) {
        top: calc(${heightNumber} * 2px);
        height: calc(100vh - (${heightNumber} * 2px));
    }

    ::slotted(natgen-side-bar), natgen-side-bar {
        position: absolute;
        height: 100%;
        z-index: 1;
    }

    natgen-side-bar.default {
        border-right: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        padding: calc(${paddingNumber} * 1px);
    }

    nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        width: auto;
        height: 100%;
    }

    .main-menu {
        justify-self: flex-start;
    }

    .logo {
        margin: 0 auto;
    }

    .end {
        justify-self: flex-end;
    }
`;
