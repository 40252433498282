import { attr } from '@microsoft/fast-element';
import { Button, buttonTemplate } from '@microsoft/fast-foundation';
import { ButtonAppearance } from '../../styles/patterns/button';
import { buttonStyles } from './button.styles';
import { NatGenIcon } from '../icon/icon';

/**
 * @element natgen-button
 */
export class NatGenButton extends Button {
    /**
     * The appearance the button should have.
     *
     * @remarks
     * HTML Attribute: appearance
     */
    @attr
    public appearance: ButtonAppearance;

    public connectedCallback() {
        super.connectedCallback();
        if (!this.appearance) {
            this.appearance = 'neutral';
        }
    }

    /**
     * Applies 'icon-only' class when there is only an SVG in the default slot
     */
    public defaultSlottedContentChanged(oldValue, newValue): void {
        const slottedElements = this.defaultSlottedContent.filter(
            x => x.nodeType === Node.ELEMENT_NODE
        );
        if (slottedElements.length === 1 && (slottedElements[0] instanceof SVGElement || slottedElements[0] instanceof NatGenIcon)) {
            this.control.classList.add('icon-only');
        } else {
            this.control.classList.remove('icon-only');
        }
    }
}

export const natGenButton = NatGenButton.compose({
    baseName: 'button',
    baseClass: Button,
    template: buttonTemplate,
    styles: buttonStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
