import { css } from "@microsoft/fast-element";
import { forcedColorsStylesheetBehavior } from "@microsoft/fast-foundation";
import { SystemColors } from "@microsoft/fast-web-utilities";
import { fillColor, neutralForegroundRest } from "../../design-tokens";

export const backgroundStyles = css`
    :host {
        background-color: ${fillColor};
        color: ${neutralForegroundRest};
    }
`.withBehaviors(
    forcedColorsStylesheetBehavior(
        css`
            :host {
                background-color: ${SystemColors.ButtonFace};
                box-shadow: 0 0 0 1px ${SystemColors.CanvasText};
                color: ${SystemColors.ButtonText};
            }
        `
));
