// Based on: https://github.com/jackocnr/intl-tel-input
export const AllCountries = [
    {
        "name": "Afghanistan (‫افغانستان‬‎)",
        "iso2": "af",
        "dialCode": "93",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-66px"
    },
    {
        "name": "Albania (Shqipëri)",
        "iso2": "al",
        "dialCode": "355",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-132px"
    },
    {
        "name": "Algeria (‫الجزائر‬‎)",
        "iso2": "dz",
        "dialCode": "213",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1401px"
    },
    {
        "name": "American Samoa",
        "iso2": "as",
        "dialCode": "1",
        "priority": 5,
        "areaCodes": [
            "684"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-242px"
    },
    {
        "name": "Andorra",
        "iso2": "ad",
        "dialCode": "376",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-22px"
    },
    {
        "name": "Angola",
        "iso2": "ao",
        "dialCode": "244",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-176px"
    },
    {
        "name": "Anguilla",
        "iso2": "ai",
        "dialCode": "1",
        "priority": 6,
        "areaCodes": [
            "264"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-110px"
    },
    {
        "name": "Antigua and Barbuda",
        "iso2": "ag",
        "dialCode": "1",
        "priority": 7,
        "areaCodes": [
            "268"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-88px"
    },
    {
        "name": "Argentina",
        "iso2": "ar",
        "dialCode": "54",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-220px"
    },
    {
        "name": "Armenia (Հայաստան)",
        "iso2": "am",
        "dialCode": "374",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-154px"
    },
    {
        "name": "Aruba",
        "iso2": "aw",
        "dialCode": "297",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-308px"
    },
    {
        "name": "Ascension Island",
        "iso2": "ac",
        "dialCode": "247",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "0px"
    },
    {
        "name": "Australia",
        "iso2": "au",
        "dialCode": "61",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-286px"
    },
    {
        "name": "Austria (Österreich)",
        "iso2": "at",
        "dialCode": "43",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-264px"
    },
    {
        "name": "Azerbaijan (Azərbaycan)",
        "iso2": "az",
        "dialCode": "994",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-352px"
    },
    {
        "name": "Bahamas",
        "iso2": "bs",
        "dialCode": "1",
        "priority": 8,
        "areaCodes": [
            "242"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-702px"
    },
    {
        "name": "Bahrain (‫البحرين‬‎)",
        "iso2": "bh",
        "dialCode": "973",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-504px"
    },
    {
        "name": "Bangladesh (বাংলাদেশ)",
        "iso2": "bd",
        "dialCode": "880",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-418px"
    },
    {
        "name": "Barbados",
        "iso2": "bb",
        "dialCode": "1",
        "priority": 9,
        "areaCodes": [
            "246"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-396px"
    },
    {
        "name": "Belarus (Беларусь)",
        "iso2": "by",
        "dialCode": "375",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-790px"
    },
    {
        "name": "Belgium (België)",
        "iso2": "be",
        "dialCode": "32",
        "priority": 0,
        "areaCodes": null,
        "width": 18,
        "height": 15,
        "backgroundPosition": "-440px"
    },
    {
        "name": "Belize",
        "iso2": "bz",
        "dialCode": "501",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-812px"
    },
    {
        "name": "Benin (Bénin)",
        "iso2": "bj",
        "dialCode": "229",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-548px"
    },
    {
        "name": "Bermuda",
        "iso2": "bm",
        "dialCode": "1",
        "priority": 10,
        "areaCodes": [
            "441"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-592px"
    },
    {
        "name": "Bhutan (འབྲུག)",
        "iso2": "bt",
        "dialCode": "975",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-724px"
    },
    {
        "name": "Bolivia",
        "iso2": "bo",
        "dialCode": "591",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-636px"
    },
    {
        "name": "Bosnia and Herzegovina (Босна и Херцеговина)",
        "iso2": "ba",
        "dialCode": "387",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-374px"
    },
    {
        "name": "Botswana",
        "iso2": "bw",
        "dialCode": "267",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-768px"
    },
    {
        "name": "Brazil (Brasil)",
        "iso2": "br",
        "dialCode": "55",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-680px"
    },
    {
        "name": "British Indian Ocean Territory",
        "iso2": "io",
        "dialCode": "246",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2435px"
    },
    {
        "name": "British Virgin Islands",
        "iso2": "vg",
        "dialCode": "1",
        "priority": 11,
        "areaCodes": [
            "284"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5390px"
    },
    {
        "name": "Brunei",
        "iso2": "bn",
        "dialCode": "673",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-614px"
    },
    {
        "name": "Bulgaria (България)",
        "iso2": "bg",
        "dialCode": "359",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-482px"
    },
    {
        "name": "Burkina Faso",
        "iso2": "bf",
        "dialCode": "226",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-460px"
    },
    {
        "name": "Burundi (Uburundi)",
        "iso2": "bi",
        "dialCode": "257",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-526px"
    },
    {
        "name": "Cambodia (កម្ពុជា)",
        "iso2": "kh",
        "dialCode": "855",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-2677px"
    },
    {
        "name": "Cameroon (Cameroun)",
        "iso2": "cm",
        "dialCode": "237",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1027px"
    },
    {
        "name": "Canada",
        "iso2": "ca",
        "dialCode": "1",
        "priority": 1,
        "areaCodes": [
            "204",
            "226",
            "236",
            "249",
            "250",
            "289",
            "306",
            "343",
            "365",
            "387",
            "403",
            "416",
            "418",
            "431",
            "437",
            "438",
            "450",
            "506",
            "514",
            "519",
            "548",
            "579",
            "581",
            "587",
            "604",
            "613",
            "639",
            "647",
            "672",
            "705",
            "709",
            "742",
            "778",
            "780",
            "782",
            "807",
            "819",
            "825",
            "867",
            "873",
            "902",
            "905"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-834px"
    },
    {
        "name": "Cape Verde (Kabu Verdi)",
        "iso2": "cv",
        "dialCode": "238",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-1159px"
    },
    {
        "name": "Caribbean Netherlands",
        "iso2": "bq",
        "dialCode": "599",
        "priority": 1,
        "areaCodes": [
            "3",
            "4",
            "7"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-658px"
    },
    {
        "name": "Cayman Islands",
        "iso2": "ky",
        "dialCode": "1",
        "priority": 12,
        "areaCodes": [
            "345"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2831px"
    },
    {
        "name": "Central African Republic (République centrafricaine)",
        "iso2": "cf",
        "dialCode": "236",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-900px"
    },
    {
        "name": "Chad (Tchad)",
        "iso2": "td",
        "dialCode": "235",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4845px"
    },
    {
        "name": "Chile",
        "iso2": "cl",
        "dialCode": "56",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1005px"
    },
    {
        "name": "China (中国)",
        "iso2": "cn",
        "dialCode": "86",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1049px"
    },
    {
        "name": "Christmas Island",
        "iso2": "cx",
        "dialCode": "61",
        "priority": 2,
        "areaCodes": [
            "89164"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1203px"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "iso2": "cc",
        "dialCode": "61",
        "priority": 1,
        "areaCodes": [
            "89162"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-856px"
    },
    {
        "name": "Colombia",
        "iso2": "co",
        "dialCode": "57",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1071px"
    },
    {
        "name": "Comoros (‫جزر القمر‬‎)",
        "iso2": "km",
        "dialCode": "269",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2721px"
    },
    {
        "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
        "iso2": "cd",
        "dialCode": "243",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-878px"
    },
    {
        "name": "Congo (Republic) (Congo-Brazzaville)",
        "iso2": "cg",
        "dialCode": "242",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-922px"
    },
    {
        "name": "Cook Islands",
        "iso2": "ck",
        "dialCode": "682",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-983px"
    },
    {
        "name": "Costa Rica",
        "iso2": "cr",
        "dialCode": "506",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-1115px"
    },
    {
        "name": "Côte d’Ivoire",
        "iso2": "ci",
        "dialCode": "225",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-961px"
    },
    {
        "name": "Croatia (Hrvatska)",
        "iso2": "hr",
        "dialCode": "385",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2237px"
    },
    {
        "name": "Cuba",
        "iso2": "cu",
        "dialCode": "53",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1137px"
    },
    {
        "name": "Curaçao",
        "iso2": "cw",
        "dialCode": "599",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1181px"
    },
    {
        "name": "Cyprus (Κύπρος)",
        "iso2": "cy",
        "dialCode": "357",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1225px"
    },
    {
        "name": "Czech Republic (Česká republika)",
        "iso2": "cz",
        "dialCode": "420",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1247px"
    },
    {
        "name": "Denmark (Danmark)",
        "iso2": "dk",
        "dialCode": "45",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-1335px"
    },
    {
        "name": "Djibouti",
        "iso2": "dj",
        "dialCode": "253",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1313px"
    },
    {
        "name": "Dominica",
        "iso2": "dm",
        "dialCode": "1",
        "priority": 13,
        "areaCodes": [
            "767"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1357px"
    },
    {
        "name": "Dominican Republic (República Dominicana)",
        "iso2": "do",
        "dialCode": "1",
        "priority": 2,
        "areaCodes": [
            "809",
            "829",
            "849"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1379px"
    },
    {
        "name": "Ecuador",
        "iso2": "ec",
        "dialCode": "593",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1445px"
    },
    {
        "name": "Egypt (‫مصر‬‎)",
        "iso2": "eg",
        "dialCode": "20",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1489px"
    },
    {
        "name": "El Salvador",
        "iso2": "sv",
        "dialCode": "503",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-4713px"
    },
    {
        "name": "Equatorial Guinea (Guinea Ecuatorial)",
        "iso2": "gq",
        "dialCode": "240",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2017px"
    },
    {
        "name": "Eritrea",
        "iso2": "er",
        "dialCode": "291",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1533px"
    },
    {
        "name": "Estonia (Eesti)",
        "iso2": "ee",
        "dialCode": "372",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-1467px"
    },
    {
        "name": "Eswatini",
        "iso2": "sz",
        "dialCode": "268",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4779px"
    },
    {
        "name": "Ethiopia",
        "iso2": "et",
        "dialCode": "251",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1577px"
    },
    {
        "name": "Falkland Islands (Islas Malvinas)",
        "iso2": "fk",
        "dialCode": "500",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1665px"
    },
    {
        "name": "Faroe Islands (Føroyar)",
        "iso2": "fo",
        "dialCode": "298",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-1709px"
    },
    {
        "name": "Fiji",
        "iso2": "fj",
        "dialCode": "679",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1643px"
    },
    {
        "name": "Finland (Suomi)",
        "iso2": "fi",
        "dialCode": "358",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-1621px"
    },
    {
        "name": "France",
        "iso2": "fr",
        "dialCode": "33",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1731px"
    },
    {
        "name": "French Guiana (Guyane française)",
        "iso2": "gf",
        "dialCode": "594",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1841px"
    },
    {
        "name": "French Polynesia (Polynésie française)",
        "iso2": "pf",
        "dialCode": "689",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3943px"
    },
    {
        "name": "Gabon",
        "iso2": "ga",
        "dialCode": "241",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-1753px"
    },
    {
        "name": "Gambia",
        "iso2": "gm",
        "dialCode": "220",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1951px"
    },
    {
        "name": "Georgia (საქართველო)",
        "iso2": "ge",
        "dialCode": "995",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1819px"
    },
    {
        "name": "Germany (Deutschland)",
        "iso2": "de",
        "dialCode": "49",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-1269px"
    },
    {
        "name": "Ghana (Gaana)",
        "iso2": "gh",
        "dialCode": "233",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1885px"
    },
    {
        "name": "Gibraltar",
        "iso2": "gi",
        "dialCode": "350",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1907px"
    },
    {
        "name": "Greece (Ελλάδα)",
        "iso2": "gr",
        "dialCode": "30",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2039px"
    },
    {
        "name": "Greenland (Kalaallit Nunaat)",
        "iso2": "gl",
        "dialCode": "299",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1929px"
    },
    {
        "name": "Grenada",
        "iso2": "gd",
        "dialCode": "1",
        "priority": 14,
        "areaCodes": [
            "473"
        ],
        "width": 20,
        "height": 12,
        "backgroundPosition": "-1797px"
    },
    {
        "name": "Guadeloupe",
        "iso2": "gp",
        "dialCode": "590",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1995px"
    },
    {
        "name": "Guam",
        "iso2": "gu",
        "dialCode": "1",
        "priority": 15,
        "areaCodes": [
            "671"
        ],
        "width": 20,
        "height": 11,
        "backgroundPosition": "-2105px"
    },
    {
        "name": "Guatemala",
        "iso2": "gt",
        "dialCode": "502",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-2083px"
    },
    {
        "name": "Guernsey",
        "iso2": "gg",
        "dialCode": "44",
        "priority": 1,
        "areaCodes": [
            "1481",
            "7781",
            "7839",
            "7911"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1863px"
    },
    {
        "name": "Guinea (Guinée)",
        "iso2": "gn",
        "dialCode": "224",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1973px"
    },
    {
        "name": "Guinea-Bissau (Guiné Bissau)",
        "iso2": "gw",
        "dialCode": "245",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2127px"
    },
    {
        "name": "Guyana",
        "iso2": "gy",
        "dialCode": "592",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2149px"
    },
    {
        "name": "Haiti",
        "iso2": "ht",
        "dialCode": "509",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2259px"
    },
    {
        "name": "Honduras",
        "iso2": "hn",
        "dialCode": "504",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2215px"
    },
    {
        "name": "Hong Kong (香港)",
        "iso2": "hk",
        "dialCode": "852",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2171px"
    },
    {
        "name": "Hungary (Magyarország)",
        "iso2": "hu",
        "dialCode": "36",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2281px"
    },
    {
        "name": "Iceland (Ísland)",
        "iso2": "is",
        "dialCode": "354",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-2501px"
    },
    {
        "name": "India (भारत)",
        "iso2": "in",
        "dialCode": "91",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2413px"
    },
    {
        "name": "Indonesia",
        "iso2": "id",
        "dialCode": "62",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2325px"
    },
    {
        "name": "Iran (‫ایران‬‎)",
        "iso2": "ir",
        "dialCode": "98",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2479px"
    },
    {
        "name": "Iraq (‫العراق‬‎)",
        "iso2": "iq",
        "dialCode": "964",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2457px"
    },
    {
        "name": "Ireland",
        "iso2": "ie",
        "dialCode": "353",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2347px"
    },
    {
        "name": "Isle of Man",
        "iso2": "im",
        "dialCode": "44",
        "priority": 2,
        "areaCodes": [
            "1624",
            "74576",
            "7524",
            "7924",
            "7624"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2391px"
    },
    {
        "name": "Israel (‫ישראל‬‎)",
        "iso2": "il",
        "dialCode": "972",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-2369px"
    },
    {
        "name": "Italy (Italia)",
        "iso2": "it",
        "dialCode": "39",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2523px"
    },
    {
        "name": "Jamaica",
        "iso2": "jm",
        "dialCode": "1",
        "priority": 4,
        "areaCodes": [
            "876",
            "658"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2567px"
    },
    {
        "name": "Japan (日本)",
        "iso2": "jp",
        "dialCode": "81",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2611px"
    },
    {
        "name": "Jersey",
        "iso2": "je",
        "dialCode": "44",
        "priority": 3,
        "areaCodes": [
            "1534",
            "7509",
            "7700",
            "7797",
            "7829",
            "7937"
        ],
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2545px"
    },
    {
        "name": "Jordan (‫الأردن‬‎)",
        "iso2": "jo",
        "dialCode": "962",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2589px"
    },
    {
        "name": "Kazakhstan (Казахстан)",
        "iso2": "kz",
        "dialCode": "7",
        "priority": 1,
        "areaCodes": [
            "33",
            "7"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2853px"
    },
    {
        "name": "Kenya",
        "iso2": "ke",
        "dialCode": "254",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2633px"
    },
    {
        "name": "Kiribati",
        "iso2": "ki",
        "dialCode": "686",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2699px"
    },
    {
        "name": "Kosovo",
        "iso2": "xk",
        "dialCode": "383",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-5522px"
    },
    {
        "name": "Kuwait (‫الكويت‬‎)",
        "iso2": "kw",
        "dialCode": "965",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2809px"
    },
    {
        "name": "Kyrgyzstan (Кыргызстан)",
        "iso2": "kg",
        "dialCode": "996",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2655px"
    },
    {
        "name": "Laos (ລາວ)",
        "iso2": "la",
        "dialCode": "856",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2875px"
    },
    {
        "name": "Latvia (Latvija)",
        "iso2": "lv",
        "dialCode": "371",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3073px"
    },
    {
        "name": "Lebanon (‫لبنان‬‎)",
        "iso2": "lb",
        "dialCode": "961",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2897px"
    },
    {
        "name": "Lesotho",
        "iso2": "ls",
        "dialCode": "266",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3007px"
    },
    {
        "name": "Liberia",
        "iso2": "lr",
        "dialCode": "231",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 11,
        "backgroundPosition": "-2985px"
    },
    {
        "name": "Libya (‫ليبيا‬‎)",
        "iso2": "ly",
        "dialCode": "218",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3095px"
    },
    {
        "name": "Liechtenstein",
        "iso2": "li",
        "dialCode": "423",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-2941px"
    },
    {
        "name": "Lithuania (Lietuva)",
        "iso2": "lt",
        "dialCode": "370",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-3029px"
    },
    {
        "name": "Luxembourg",
        "iso2": "lu",
        "dialCode": "352",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-3051px"
    },
    {
        "name": "Macau (澳門)",
        "iso2": "mo",
        "dialCode": "853",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3358px"
    },
    {
        "name": "North Macedonia (Македонија)",
        "iso2": "mk",
        "dialCode": "389",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3270px"
    },
    {
        "name": "Madagascar (Madagasikara)",
        "iso2": "mg",
        "dialCode": "261",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3226px"
    },
    {
        "name": "Malawi",
        "iso2": "mw",
        "dialCode": "265",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3534px"
    },
    {
        "name": "Malaysia",
        "iso2": "my",
        "dialCode": "60",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3578px"
    },
    {
        "name": "Maldives",
        "iso2": "mv",
        "dialCode": "960",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3512px"
    },
    {
        "name": "Mali",
        "iso2": "ml",
        "dialCode": "223",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3292px"
    },
    {
        "name": "Malta",
        "iso2": "mt",
        "dialCode": "356",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3468px"
    },
    {
        "name": "Marshall Islands",
        "iso2": "mh",
        "dialCode": "692",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 11,
        "backgroundPosition": "-3248px"
    },
    {
        "name": "Martinique",
        "iso2": "mq",
        "dialCode": "596",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3402px"
    },
    {
        "name": "Mauritania (‫موريتانيا‬‎)",
        "iso2": "mr",
        "dialCode": "222",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3424px"
    },
    {
        "name": "Mauritius (Moris)",
        "iso2": "mu",
        "dialCode": "230",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3490px"
    },
    {
        "name": "Mayotte",
        "iso2": "yt",
        "dialCode": "262",
        "priority": 1,
        "areaCodes": [
            "269",
            "639"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5566px"
    },
    {
        "name": "Mexico (México)",
        "iso2": "mx",
        "dialCode": "52",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-3556px"
    },
    {
        "name": "Micronesia",
        "iso2": "fm",
        "dialCode": "691",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 11,
        "backgroundPosition": "-1687px"
    },
    {
        "name": "Moldova (Republica Moldova)",
        "iso2": "md",
        "dialCode": "373",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3160px"
    },
    {
        "name": "Monaco",
        "iso2": "mc",
        "dialCode": "377",
        "priority": 0,
        "areaCodes": null,
        "width": 19,
        "height": 15,
        "backgroundPosition": "-3139px"
    },
    {
        "name": "Mongolia (Монгол)",
        "iso2": "mn",
        "dialCode": "976",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3336px"
    },
    {
        "name": "Montenegro (Crna Gora)",
        "iso2": "me",
        "dialCode": "382",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3182px"
    },
    {
        "name": "Montserrat",
        "iso2": "ms",
        "dialCode": "1",
        "priority": 16,
        "areaCodes": [
            "664"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3446px"
    },
    {
        "name": "Morocco (‫المغرب‬‎)",
        "iso2": "ma",
        "dialCode": "212",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3117px"
    },
    {
        "name": "Mozambique (Moçambique)",
        "iso2": "mz",
        "dialCode": "258",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3600px"
    },
    {
        "name": "Myanmar (Burma) (မြန်မာ)",
        "iso2": "mm",
        "dialCode": "95",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3314px"
    },
    {
        "name": "Namibia (Namibië)",
        "iso2": "na",
        "dialCode": "264",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3622px"
    },
    {
        "name": "Nauru",
        "iso2": "nr",
        "dialCode": "674",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3811px"
    },
    {
        "name": "Nepal (नेपाल)",
        "iso2": "np",
        "dialCode": "977",
        "priority": 0,
        "areaCodes": null,
        "width": 13,
        "height": 15,
        "backgroundPosition": "-3796px"
    },
    {
        "name": "Netherlands (Nederland)",
        "iso2": "nl",
        "dialCode": "31",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3752px"
    },
    {
        "name": "New Caledonia (Nouvelle-Calédonie)",
        "iso2": "nc",
        "dialCode": "687",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3644px"
    },
    {
        "name": "New Zealand",
        "iso2": "nz",
        "dialCode": "64",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3855px"
    },
    {
        "name": "Nicaragua",
        "iso2": "ni",
        "dialCode": "505",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-3730px"
    },
    {
        "name": "Niger (Nijar)",
        "iso2": "ne",
        "dialCode": "227",
        "priority": 0,
        "areaCodes": null,
        "width": 18,
        "height": 15,
        "backgroundPosition": "-3666px"
    },
    {
        "name": "Nigeria",
        "iso2": "ng",
        "dialCode": "234",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3708px"
    },
    {
        "name": "Niue",
        "iso2": "nu",
        "dialCode": "683",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3833px"
    },
    {
        "name": "Norfolk Island",
        "iso2": "nf",
        "dialCode": "672",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3686px"
    },
    {
        "name": "North Korea (조선 민주주의 인민 공화국)",
        "iso2": "kp",
        "dialCode": "850",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2765px"
    },
    {
        "name": "Northern Mariana Islands",
        "iso2": "mp",
        "dialCode": "1",
        "priority": 17,
        "areaCodes": [
            "670"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3380px"
    },
    {
        "name": "Norway (Norge)",
        "iso2": "no",
        "dialCode": "47",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-3774px"
    },
    {
        "name": "Oman (‫عُمان‬‎)",
        "iso2": "om",
        "dialCode": "968",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3877px"
    },
    {
        "name": "Pakistan (‫پاکستان‬‎)",
        "iso2": "pk",
        "dialCode": "92",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4009px"
    },
    {
        "name": "Palau",
        "iso2": "pw",
        "dialCode": "680",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-4163px"
    },
    {
        "name": "Palestine (‫فلسطين‬‎)",
        "iso2": "ps",
        "dialCode": "970",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4119px"
    },
    {
        "name": "Panama (Panamá)",
        "iso2": "pa",
        "dialCode": "507",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3899px"
    },
    {
        "name": "Papua New Guinea",
        "iso2": "pg",
        "dialCode": "675",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-3965px"
    },
    {
        "name": "Paraguay",
        "iso2": "py",
        "dialCode": "595",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 11,
        "backgroundPosition": "-4185px"
    },
    {
        "name": "Peru (Perú)",
        "iso2": "pe",
        "dialCode": "51",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3921px"
    },
    {
        "name": "Philippines",
        "iso2": "ph",
        "dialCode": "63",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-3987px"
    },
    {
        "name": "Poland (Polska)",
        "iso2": "pl",
        "dialCode": "48",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-4031px"
    },
    {
        "name": "Portugal",
        "iso2": "pt",
        "dialCode": "351",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4141px"
    },
    {
        "name": "Puerto Rico",
        "iso2": "pr",
        "dialCode": "1",
        "priority": 3,
        "areaCodes": [
            "787",
            "939"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4097px"
    },
    {
        "name": "Qatar (‫قطر‬‎)",
        "iso2": "qa",
        "dialCode": "974",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 8,
        "backgroundPosition": "-4207px"
    },
    {
        "name": "Réunion (La Réunion)",
        "iso2": "re",
        "dialCode": "262",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4229px"
    },
    {
        "name": "Romania (România)",
        "iso2": "ro",
        "dialCode": "40",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4251px"
    },
    {
        "name": "Russia (Россия)",
        "iso2": "ru",
        "dialCode": "7",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4295px"
    },
    {
        "name": "Rwanda",
        "iso2": "rw",
        "dialCode": "250",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4317px"
    },
    {
        "name": "Saint Barthélemy",
        "iso2": "bl",
        "dialCode": "590",
        "priority": 1,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-570px"
    },
    {
        "name": "Saint Helena",
        "iso2": "sh",
        "dialCode": "290",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4471px"
    },
    {
        "name": "Saint Kitts and Nevis",
        "iso2": "kn",
        "dialCode": "1",
        "priority": 18,
        "areaCodes": [
            "869"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2743px"
    },
    {
        "name": "Saint Lucia",
        "iso2": "lc",
        "dialCode": "1",
        "priority": 19,
        "areaCodes": [
            "758"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2919px"
    },
    {
        "name": "Saint Martin (Saint-Martin (partie française))",
        "iso2": "mf",
        "dialCode": "590",
        "priority": 2,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-3204px"
    },
    {
        "name": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
        "iso2": "pm",
        "dialCode": "508",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4053px"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "iso2": "vc",
        "dialCode": "1",
        "priority": 20,
        "areaCodes": [
            "784"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5346px"
    },
    {
        "name": "Samoa",
        "iso2": "ws",
        "dialCode": "685",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5500px"
    },
    {
        "name": "San Marino",
        "iso2": "sm",
        "dialCode": "378",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 15,
        "backgroundPosition": "-4581px"
    },
    {
        "name": "São Tomé and Príncipe (São Tomé e Príncipe)",
        "iso2": "st",
        "dialCode": "239",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4691px"
    },
    {
        "name": "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
        "iso2": "sa",
        "dialCode": "966",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4339px"
    },
    {
        "name": "Senegal (Sénégal)",
        "iso2": "sn",
        "dialCode": "221",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4603px"
    },
    {
        "name": "Serbia (Србија)",
        "iso2": "rs",
        "dialCode": "381",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4273px"
    },
    {
        "name": "Seychelles",
        "iso2": "sc",
        "dialCode": "248",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4383px"
    },
    {
        "name": "Sierra Leone",
        "iso2": "sl",
        "dialCode": "232",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4559px"
    },
    {
        "name": "Singapore",
        "iso2": "sg",
        "dialCode": "65",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4449px"
    },
    {
        "name": "Sint Maarten",
        "iso2": "sx",
        "dialCode": "1",
        "priority": 21,
        "areaCodes": [
            "721"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4735px"
    },
    {
        "name": "Slovakia (Slovensko)",
        "iso2": "sk",
        "dialCode": "421",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4537px"
    },
    {
        "name": "Slovenia (Slovenija)",
        "iso2": "si",
        "dialCode": "386",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4493px"
    },
    {
        "name": "Solomon Islands",
        "iso2": "sb",
        "dialCode": "677",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4361px"
    },
    {
        "name": "Somalia (Soomaaliya)",
        "iso2": "so",
        "dialCode": "252",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4625px"
    },
    {
        "name": "South Africa",
        "iso2": "za",
        "dialCode": "27",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5588px"
    },
    {
        "name": "South Korea (대한민국)",
        "iso2": "kr",
        "dialCode": "82",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-2787px"
    },
    {
        "name": "South Sudan (‫جنوب السودان‬‎)",
        "iso2": "ss",
        "dialCode": "211",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4669px"
    },
    {
        "name": "Spain (España)",
        "iso2": "es",
        "dialCode": "34",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-1555px"
    },
    {
        "name": "Sri Lanka (ශ්‍රී ලංකාව)",
        "iso2": "lk",
        "dialCode": "94",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-2963px"
    },
    {
        "name": "Sudan (‫السودان‬‎)",
        "iso2": "sd",
        "dialCode": "249",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4405px"
    },
    {
        "name": "Suriname",
        "iso2": "sr",
        "dialCode": "597",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4647px"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "iso2": "sj",
        "dialCode": "47",
        "priority": 1,
        "areaCodes": [
            "79"
        ],
        "width": 20,
        "height": 15,
        "backgroundPosition": "-4515px"
    },
    {
        "name": "Sweden (Sverige)",
        "iso2": "se",
        "dialCode": "46",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-4427px"
    },
    {
        "name": "Switzerland (Schweiz)",
        "iso2": "ch",
        "dialCode": "41",
        "priority": 0,
        "areaCodes": null,
        "width": 15,
        "height": 15,
        "backgroundPosition": "-944px"
    },
    {
        "name": "Syria (‫سوريا‬‎)",
        "iso2": "sy",
        "dialCode": "963",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4757px"
    },
    {
        "name": "Taiwan (台灣)",
        "iso2": "tw",
        "dialCode": "886",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5131px"
    },
    {
        "name": "Tajikistan",
        "iso2": "tj",
        "dialCode": "992",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4933px"
    },
    {
        "name": "Tanzania",
        "iso2": "tz",
        "dialCode": "255",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5153px"
    },
    {
        "name": "Thailand (ไทย)",
        "iso2": "th",
        "dialCode": "66",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4911px"
    },
    {
        "name": "Timor-Leste",
        "iso2": "tl",
        "dialCode": "670",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4977px"
    },
    {
        "name": "Togo",
        "iso2": "tg",
        "dialCode": "228",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 13,
        "backgroundPosition": "-4889px"
    },
    {
        "name": "Tokelau",
        "iso2": "tk",
        "dialCode": "690",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4955px"
    },
    {
        "name": "Tonga",
        "iso2": "to",
        "dialCode": "676",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5043px"
    },
    {
        "name": "Trinidad and Tobago",
        "iso2": "tt",
        "dialCode": "1",
        "priority": 22,
        "areaCodes": [
            "868"
        ],
        "width": 20,
        "height": 12,
        "backgroundPosition": "-5087px"
    },
    {
        "name": "Tunisia (‫تونس‬‎)",
        "iso2": "tn",
        "dialCode": "216",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5021px"
    },
    {
        "name": "Turkey (Türkiye)",
        "iso2": "tr",
        "dialCode": "90",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5065px"
    },
    {
        "name": "Turkmenistan",
        "iso2": "tm",
        "dialCode": "993",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-4999px"
    },
    {
        "name": "Turks and Caicos Islands",
        "iso2": "tc",
        "dialCode": "1",
        "priority": 23,
        "areaCodes": [
            "649"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-4823px"
    },
    {
        "name": "Tuvalu",
        "iso2": "tv",
        "dialCode": "688",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5109px"
    },
    {
        "name": "U.S. Virgin Islands",
        "iso2": "vi",
        "dialCode": "1",
        "priority": 24,
        "areaCodes": [
            "340"
        ],
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5412px"
    },
    {
        "name": "Uganda",
        "iso2": "ug",
        "dialCode": "256",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5197px"
    },
    {
        "name": "Ukraine (Україна)",
        "iso2": "ua",
        "dialCode": "380",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5175px"
    },
    {
        "name": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
        "iso2": "ae",
        "dialCode": "971",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-44px"
    },
    {
        "name": "United Kingdom",
        "iso2": "gb",
        "dialCode": "44",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1775px"
    },
    {
        "name": "United States",
        "iso2": "us",
        "dialCode": "1",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 11,
        "backgroundPosition": "-5263px"
    },
    {
        "name": "Uruguay",
        "iso2": "uy",
        "dialCode": "598",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5285px"
    },
    {
        "name": "Uzbekistan (Oʻzbekiston)",
        "iso2": "uz",
        "dialCode": "998",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5307px"
    },
    {
        "name": "Vanuatu",
        "iso2": "vu",
        "dialCode": "678",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 12,
        "backgroundPosition": "-5456px"
    },
    {
        "name": "Vatican City (Città del Vaticano)",
        "iso2": "va",
        "dialCode": "39",
        "priority": 1,
        "areaCodes": [
            "06698"
        ],
        "width": 15,
        "height": 15,
        "backgroundPosition": "-5329px"
    },
    {
        "name": "Venezuela",
        "iso2": "ve",
        "dialCode": "58",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5368px"
    },
    {
        "name": "Vietnam (Việt Nam)",
        "iso2": "vn",
        "dialCode": "84",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5434px"
    },
    {
        "name": "Wallis and Futuna (Wallis-et-Futuna)",
        "iso2": "wf",
        "dialCode": "681",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5478px"
    },
    {
        "name": "Western Sahara (‫الصحراء الغربية‬‎)",
        "iso2": "eh",
        "dialCode": "212",
        "priority": 1,
        "areaCodes": [
            "5288",
            "5289"
        ],
        "width": 20,
        "height": 10,
        "backgroundPosition": "-1511px"
    },
    {
        "name": "Yemen (‫اليمن‬‎)",
        "iso2": "ye",
        "dialCode": "967",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5544px"
    },
    {
        "name": "Zambia",
        "iso2": "zm",
        "dialCode": "260",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 14,
        "backgroundPosition": "-5610px"
    },
    {
        "name": "Zimbabwe",
        "iso2": "zw",
        "dialCode": "263",
        "priority": 0,
        "areaCodes": null,
        "width": 20,
        "height": 10,
        "backgroundPosition": "-5632px"
    },
    {
        "name": "Åland Islands",
        "iso2": "ax",
        "dialCode": "358",
        "priority": 1,
        "areaCodes": [
            "18"
        ],
        "width": 20,
        "height": 13,
        "backgroundPosition": "-330px"
    }
];
