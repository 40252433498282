import { ColorRGBA64, rgbToRelativeLuminance } from "@microsoft/fast-colors";
import { RelativeLuminance } from "./luminance";
import { Luminance } from "./recipe";

/**
 * Represents a color in a {@link Palette}.
 */
export interface Swatch extends RelativeLuminance {
    toColorString(): string;
    contrast(target: RelativeLuminance): number;
}

/**
 * A concrete implementation of {@link Swatch} with red, green, and blue 64bit color channels.
 * */
export class SwatchRGB extends ColorRGBA64 implements Swatch {
    readonly relativeLuminance: number;
    r: number;
    g: number;
    b: number;

    /**
    *
    * @param red - Red channel expressed as a number between 0 and 1
    * @param green - Green channel expressed as a number between 0 and 1
    * @param blue - Blue channel expressed as a number between 0 and 1
    */
    constructor(red: number, green: number, blue: number) {
        super(red, green, blue, 1);
        this.relativeLuminance = rgbToRelativeLuminance(this);
    }

    public toColorString = this.toStringHexRGB;
    public contrast = Luminance.getContrast.bind(null, this);
    public createCSS = this.toColorString;

    static fromObject(obj: { r: number; g: number; b: number }): SwatchRGB {
        return new SwatchRGB(obj.r, obj.g, obj.b);
    }
}

export const white = new SwatchRGB(1, 1, 1);
export const black = new SwatchRGB(0, 0, 0);
