import { attr, observable } from '@microsoft/fast-element';
import { Dialog } from '@microsoft/fast-foundation';
import { dialogStyles } from './dialog.styles';
import { dialogTemplate } from './dialog.template';

function dismissFunction() {
    this.hidden = true;
}

/**
 * @element natgen-dialog
 */
export class NatGenDialog extends Dialog {
    @observable
    public slottedActionNodes: Node[];
    slottedActionNodesChanged(oldValue: Node[], newValue: Node[]) {
        oldValue?.forEach(n => {
            n.removeEventListener('click', dismissFunction.bind(this));
        });

        newValue?.forEach(n => {
            if(this.autoDismiss)
                n.addEventListener('click', dismissFunction.bind(this));
        });
    }

    @attr({ mode: 'boolean', attribute: 'auto-dismiss' })
    autoDismiss: boolean;

    autoDismissChanged(oldValue: boolean, newValue: boolean): void {
        this.slottedActionNodes?.forEach(n => {
            if(newValue)
                n.addEventListener('click', dismissFunction.bind(this));
            else
                n.removeEventListener('click', dismissFunction.bind(this));
        });
    }
}

export const natGenDialog = NatGenDialog.compose({
    baseName: 'dialog',
    baseClass: Dialog,
    template: dialogTemplate,
    styles: dialogStyles
});
