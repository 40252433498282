import { css, ElementStyles } from '@microsoft/fast-element';
import { CheckboxOptions, disabledCursor, display, focusVisible, forcedColorsStylesheetBehavior, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { accentFillActive, accentFillFocus, accentFillHover, accentFillRest, focusStrokeOuter, foregroundOnAccentRest, neutralForegroundActive, neutralForegroundRest, neutralInputFillActive, neutralInputFillHover, neutralInputFillRest, neutralStrokeActive, neutralStrokeFocus, neutralStrokeHover, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber } from '../../styles/size';

// TODO: Token for max checkbox border radius?

export const checkboxStyles: FoundationElementTemplate<ElementStyles, CheckboxOptions> = (
    context,
    definition
) =>
    css`
    ${display("inline-flex")} :host {
        align-items: center;
        outline: none;
        margin: calc(${designTokens['space-design-unit'].token} * 1px) 0;
        ${
            /*
             * Chromium likes to select label text or the default slot when
             * the checkbox is clicked. Maybe there is a better solution here?
             */ ""
        } user-select: none;
    }
    .control {
        position: relative;
        padding: calc(${designTokens['space-design-unit'].token} * 0.5px);
        width: calc(((${heightNumber} / 2) + (${designTokens['space-stroke-width'].token} * 2)) * 1px);
        height: calc(((${heightNumber} / 2) + (${designTokens['space-stroke-width'].token} * 2)) * 1px);
        box-sizing: border-box;
        border-radius: min(calc(${designTokens['space-input-corner-radius'].token} * 1px), 4px);
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        background: ${neutralInputFillRest};
        outline: none;
        cursor: pointer;
    }
    :host(:hover) .control {
        background: ${neutralInputFillHover};
        border-color: ${neutralStrokeHover};
    }
    :host(:active) .control {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeActive};
    }
    :host(:${focusVisible}) .control {
        border-color: ${neutralStrokeFocus};
        outline: calc(${designTokens['space-focus-stroke-width'].token} * 1px) solid ${focusStrokeOuter};
        outline-offset: calc(${designTokens['space-stroke-width'].token} * 1px);
    }
    :host([aria-checked="true"]) .control {
        background: ${accentFillRest};
        border-color: ${accentFillRest};
    }
    :host([aria-checked="true"]:enabled:hover) .control {
        background: ${accentFillHover};
        border-color: ${accentFillHover};
    }
    :host([aria-checked="true"]:enabled:active) .control {
        background: ${accentFillActive};
        border-color: ${accentFillActive};
    }
    :host([aria-checked="true"]:enabled:${focusVisible}) .control {
        background: ${accentFillFocus};
        border-color: ${accentFillFocus};
    }
    .label__hidden {
        display: none;
        visibility: hidden;
    }
    .label {
        font-family: ${designTokens['typography-font-family'].token};
        color: ${neutralForegroundRest};
        padding-inline-start: calc(${designTokens['space-design-unit'].token} * 2px + 2px);
        cursor: pointer;
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
    }
    slot[name="checked-indicator"],
    slot[name="indeterminate-indicator"] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
    }
    slot[name="checked-indicator"] {
        fill: ${foregroundOnAccentRest};
    }
    slot[name="indeterminate-indicator"] {
        fill: ${neutralForegroundActive};
        position: absolute;
        top: 0;
    }
    :host(.disabled) .label,
    :host(.readonly) .label,
    :host(.readonly) .control,
    :host(.disabled) .control {
        cursor: ${disabledCursor};
    }
    :host([aria-checked="true"]:not(.indeterminate)) slot[name='checked-indicator'],
    :host([aria-checked="true"].indeterminate) slot[name='indeterminate-indicator'] {
        opacity: 1;
    }
    :host(.disabled) {
        opacity: ${designTokens['color-disabled-opacity'].token};
    }
    :host([aria-checked="true"].indeterminate) .control {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeRest};
    }
    :host([aria-checked="true"].indeterminate:hover) .control {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeHover};
    }
    :host([aria-checked="true"].indeterminate:active) .control {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeActive};
    }
    :host([aria-checked="true"].indeterminate:${focusVisible}) .control {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeFocus};
        box-shadow: 0 0 0 calc(${designTokens['space-stroke-width'].token} * 1px) ${neutralInputFillRest},
        0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) ${neutralStrokeFocus};
    }
  `.withBehaviors(
        forcedColorsStylesheetBehavior(
            css`
                .control {
                    border-color: ${SystemColors.FieldText};
                    background: ${SystemColors.Field};
                }
                slot[name='checked-indicator'],
                slot[name='indeterminate-indicator'] {
                    fill: ${SystemColors.FieldText};
                }
                :host(:enabled:hover) .control,
                :host(:enabled:active) .control {
                    border-color: ${SystemColors.Highlight};
                    background: ${SystemColors.Field};
                }
                :host(:enabled:${focusVisible}) .control,
                :host(.checked:enabled:${focusVisible}) .control {
                forced-color-adjust: none;
                    background: ${SystemColors.Field};
                    border-color: ${SystemColors.FieldText};
                    box-shadow: 0 0 0 calc(${designTokens['space-focus-stroke-width'].token} * 1px) ${SystemColors.FieldText};
                }
                :host(.checked) .control {
                    background: ${SystemColors.Highlight};
                    border-color: ${SystemColors.Highlight};
                }
                :host(.checked:enabled:hover) .control,
                :host(.checked:enabled:active) .control {
                    background: ${SystemColors.Field};
                    border-color: ${SystemColors.Highlight}
                }
                :host(.checked) slot[name='checked-indicator'],
                :host(.checked) slot[name='indeterminate-indicator'] {
                    fill: ${SystemColors.HighlightText};
                }
                :host(.checked:enabled:hover) slot[name='checked-indicator'],
                :host(.checked:enabled:${focusVisible}) slot[name='checked-indicator'],
                :host(.checked:enabled:hover) slot[name='indeterminate-indicator'],
                :host(.checked:enabled:${focusVisible}) slot[name='indeterminate-indicator'] {
                    fill: ${SystemColors.Highlight};
                }
                :host(.disabled) {
                    opacity: 1;
                }
                :host(.disabled) .label {
                    color: ${SystemColors.GrayText};
                }
                :host(.disabled) .control {
                    border-color: ${SystemColors.GrayText};
                    background: ${SystemColors.Field};
                }
                :host(.disabled) slot[name='indeterminate-indicator'],
                :host(.checked.disabled) .control:hover slot[name='indeterminate-indicator'] {
                    color: ${SystemColors.GrayText};
                    fill: currentcolor;
                }
                :host(.disabled) slot[name='checked-indicator'],
                :host(.checked.disabled) .control:hover slot[name='checked-indicator'] {
                    color: ${SystemColors.GrayText};
                    fill: currentcolor;
                }
            `
        )
    );
