import { parseColorHexRGB } from '@microsoft/fast-colors';
import { DesignToken } from '@microsoft/fast-foundation';
import { Direction } from "@microsoft/fast-web-utilities";
import { SwatchRGB, Palette, PaletteRGB, Swatch, InteractiveSwatchSet, ContrastTarget, calculateNeutralLayer, calculateFocusStrokeOuter, calculateFocusStrokeInner, calculateForegroundOnColor, calculateDeltaSwatch } from './color';

// TODO: generate design tokens at build time as preparation for design API?
import designSystemDefaults from './design-tokens/default.tokens.json';
import { NatGenDesignToken } from './design-tokens/design-token';

const { create } = DesignToken;

export interface Recipe<T> {
    evaluate(element: HTMLElement, reference?: Swatch): T;
}

export let designTokens: Record<string, NatGenDesignToken<any>> = {};

export function createDesignTokensFromJson(jsonRoot) {
    (function getTokens(root, path = '', type = '') {
        type = root.$type || type;

        if ('$value' in root) {
            switch (type) {
                case "number":
                    designTokens[path] = new NatGenDesignToken<number>({
                        defaultValue: root.$value,
                        name: path
                    });
                    break;

                case "color":
                    const color = parseColorHexRGB(root.$value);
                    designTokens[path] = new NatGenDesignToken<SwatchRGB>({
                        defaultValue: new SwatchRGB(color.r, color.g, color.b),
                        name: path
                    });
                    break;

                default:
                    designTokens[path] = new NatGenDesignToken<string>({
                        defaultValue: root.$value,
                        name: path
                    });
            }

            designTokens[path].create();
        } else {
            Object.keys(root).forEach(key => {
                if(!key.startsWith('$'))
                    getTokens(root[key], path.length > 0 ? `${path}-${key}` : key, type)
            });
        }
    })(jsonRoot);
}

export function overrideDesignTokensFromJson(jsonRoot, element: HTMLElement = document.body) {
    (function getTokens(root, path = '', type = '') {
        type = root.$type || type;

        if ('$value' in root) {
            switch (type) {
                case "color":
                    const color = parseColorHexRGB(root.$value);
                    designTokens[path].setValueFor(element, new SwatchRGB(color.r, color.g, color.b));
                    break;

                default:
                    designTokens[path].setValueFor(element, root.$value);
            }
        } else {
            Object.keys(root).forEach(key => {
                if(!key.startsWith('$'))
                    getTokens(root[key], path.length > 0 ? `${path}-${key}` : key, type)
            });
        }
    })(jsonRoot);
}

createDesignTokensFromJson(designSystemDefaults);

export const brand = create<string>({
    name: 'brand',
    cssCustomPropertyName: null
}).withDefault('ngic');

// Color

export const neutralPalette = create<Palette>({
    name: 'neutral-palette',
    cssCustomPropertyName: null
}).withDefault(element => PaletteRGB.withComponentStateColorPalette(designTokens['color-neutral'].getValueFor(element) as SwatchRGB));

export const accentPalette = create<Palette>({
    name: 'accent-palette',
    cssCustomPropertyName: null
}).withDefault(element => PaletteRGB.withComponentStateColorPalette(designTokens['color-accent'].getValueFor(element) as SwatchRGB));

export const secondaryPalette = create<Palette>({
    name: 'secondary-palette',
    cssCustomPropertyName: null
}).withDefault(element => PaletteRGB.withComponentStateColorPalette(designTokens['color-secondary'].getValueFor(element) as SwatchRGB));

// Color Recipes

export const neutralLayer1Recipe = create<Recipe<Swatch>>({
    name: 'neutral-layer-1-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateNeutralLayer(
            neutralPalette.getValueFor(element),
            designTokens['color-base-layer-luminance'].getValueFor(element)
        )
});

export const neutralLayer2Recipe = create<Recipe<Swatch>>({
    name: 'neutral-layer-2-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateNeutralLayer(
            neutralPalette.getValueFor(element),
            designTokens['color-base-layer-luminance'].getValueFor(element),
            designTokens['color-recipe-neutral-layer-fill-rest'].getValueFor(element)
        )
});

export const neutralLayer3Recipe = create<Recipe<Swatch>>({
    name: 'neutral-layer-3-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateNeutralLayer(
            neutralPalette.getValueFor(element),
            designTokens['color-base-layer-luminance'].getValueFor(element),
            designTokens['color-recipe-neutral-layer-fill-rest'].getValueFor(element) * 2
        )
});

export const neutralLayer4Recipe = create<Recipe<Swatch>>({
    name: 'neutral-layer-4-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateNeutralLayer(
            neutralPalette.getValueFor(element),
            designTokens['color-base-layer-luminance'].getValueFor(element),
            designTokens['color-recipe-neutral-layer-fill-rest'].getValueFor(element) * 3
        )
});

export const neutralFillRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'neutral-fill-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.create(
            neutralPalette.getValueFor(element),
            reference || fillColor.getValueFor(element),
            designTokens['color-recipe-neutral-fill-rest'].getValueFor(element),
            designTokens['color-recipe-neutral-fill-hover'].getValueFor(element),
            designTokens['color-recipe-neutral-fill-active'].getValueFor(element),
            designTokens['color-recipe-neutral-fill-focus'].getValueFor(element)
        )
});

export const neutralForegroundRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'neutral-foreground-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement): InteractiveSwatchSet =>
        InteractiveSwatchSet.createFromContrast(
            neutralPalette.getValueFor(element),
            fillColor.getValueFor(element),
            ContrastTarget.Text, // TODO: Should this be a token?
            designTokens['color-recipe-neutral-foreground-rest'].getValueFor(element),
            designTokens['color-recipe-neutral-foreground-hover'].getValueFor(element),
            designTokens['color-recipe-neutral-foreground-active'].getValueFor(element),
            designTokens['color-recipe-neutral-foreground-focus'].getValueFor(element)
        )
});

export const neutralInputFillRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'neutral-input-fill-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.create(
            neutralPalette.getValueFor(element),
            reference || fillColor.getValueFor(element),
            designTokens['color-recipe-neutral-input-fill-rest'].getValueFor(element),
            designTokens['color-recipe-neutral-input-fill-hover'].getValueFor(element),
            designTokens['color-recipe-neutral-input-fill-active'].getValueFor(element),
            designTokens['color-recipe-neutral-input-fill-focus'].getValueFor(element)
        )
});

export const neutralStealthFillRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'neutral-stealth-fill-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.create(
            neutralPalette.getValueFor(element),
            reference || fillColor.getValueFor(element),
            designTokens['color-recipe-neutral-stealth-fill-rest'].getValueFor(element),
            designTokens['color-recipe-neutral-stealth-fill-hover'].getValueFor(element),
            designTokens['color-recipe-neutral-stealth-fill-active'].getValueFor(element),
            designTokens['color-recipe-neutral-stealth-fill-focus'].getValueFor(element)
        )
});

export const accentFillRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'accent-fill-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.createFromIdealColor(
            accentPalette.getValueFor(element),
            accentPalette.getValueFor(element).source,
            reference || fillColor.getValueFor(element),
            ContrastTarget.Normal,
            designTokens['color-recipe-accent-fill-rest'].getValueFor(element),
            designTokens['color-recipe-accent-fill-hover'].getValueFor(element),
            designTokens['color-recipe-accent-fill-active'].getValueFor(element),
            designTokens['color-recipe-accent-fill-focus'].getValueFor(element)
        )
});

export const accentForegroundRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'accent-foreground-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.createFromIdealColor(
            accentPalette.getValueFor(element),
            accentPalette.getValueFor(element).source,
            reference || fillColor.getValueFor(element),
            ContrastTarget.Normal,
            designTokens['color-recipe-accent-foreground-rest'].getValueFor(element),
            designTokens['color-recipe-accent-foreground-hover'].getValueFor(element),
            designTokens['color-recipe-accent-foreground-active'].getValueFor(element),
            designTokens['color-recipe-accent-foreground-focus'].getValueFor(element)
        )
});

export const secondaryFillRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'secondary-fill-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.createFromIdealColor(
            secondaryPalette.getValueFor(element),
            secondaryPalette.getValueFor(element).source,
            reference || fillColor.getValueFor(element),
            ContrastTarget.Normal,
            designTokens['color-recipe-secondary-fill-rest'].getValueFor(element),
            designTokens['color-recipe-secondary-fill-hover'].getValueFor(element),
            designTokens['color-recipe-secondary-fill-active'].getValueFor(element),
            designTokens['color-recipe-secondary-fill-focus'].getValueFor(element)
        )
});

export const secondaryForegroundRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: 'secondary-foreground-recipe',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): InteractiveSwatchSet =>
        InteractiveSwatchSet.createFromIdealColor(
            secondaryPalette.getValueFor(element),
            secondaryPalette.getValueFor(element).source,
            reference || fillColor.getValueFor(element),
            ContrastTarget.Normal,
            designTokens['color-recipe-secondary-foreground-rest'].getValueFor(element),
            designTokens['color-recipe-secondary-foreground-hover'].getValueFor(element),
            designTokens['color-recipe-secondary-foreground-active'].getValueFor(element),
            designTokens['color-recipe-secondary-foreground-focus'].getValueFor(element)
        )
});

export const neutralStrokeRecipe = create<Recipe<InteractiveSwatchSet>>({
    name: "neutral-stroke-recipe",
    cssCustomPropertyName: null,
}).withDefault({
    evaluate: (element: HTMLElement): InteractiveSwatchSet =>
        InteractiveSwatchSet.create(
            neutralPalette.getValueFor(element),
            fillColor.getValueFor(element),
            designTokens['color-recipe-neutral-stroke-rest'].getValueFor(element),
            designTokens['color-recipe-neutral-stroke-hover'].getValueFor(element),
            designTokens['color-recipe-neutral-stroke-active'].getValueFor(element),
            designTokens['color-recipe-neutral-stroke-focus'].getValueFor(element)
        ),
});

export const neutralDividerRecipe = create<Recipe<Swatch>>({
    name: 'neutral-divider',
    cssCustomPropertyName: null
}).withDefault({
    evaluate: (element: HTMLElement, reference?: Swatch): Swatch =>
        calculateDeltaSwatch(
            neutralPalette.getValueFor(element),
            reference || fillColor.getValueFor(element),
            designTokens['color-recipe-neutral-divider-rest'].getValueFor(element)
        )
});

export const focusStrokeOuterRecipe = create<Recipe<Swatch>>({
    name: "focus-stroke-outer-recipe",
    cssCustomPropertyName: null,
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateFocusStrokeOuter(
            neutralPalette.getValueFor(element),
            fillColor.getValueFor(element)
        ),
});

export const focusStrokeInnerRecipe = create<Recipe<Swatch>>({
    name: "focus-stroke-inner-recipe",
    cssCustomPropertyName: null,
}).withDefault({
    evaluate: (element: HTMLElement): Swatch =>
        calculateFocusStrokeInner(
            neutralPalette.getValueFor(element),
            fillColor.getValueFor(element),
            focusStrokeOuterRecipe.getValueFor(element).evaluate(element)
        ),
});

// Recipe Colors

export const neutralLayer1 = create<Swatch>('neutral-layer-1').withDefault(element =>
    neutralLayer1Recipe.getValueFor(element).evaluate(element)
);

export const neutralLayer2 = create<Swatch>('neutral-layer-2').withDefault(element =>
    neutralLayer2Recipe.getValueFor(element).evaluate(element)
);

export const neutralLayer3 = create<Swatch>('neutral-layer-3').withDefault(element =>
    neutralLayer3Recipe.getValueFor(element).evaluate(element)
);

export const neutralLayer4 = create<Swatch>('neutral-layer-4').withDefault(element =>
    neutralLayer4Recipe.getValueFor(element).evaluate(element)
);

export const fillColor = create<Swatch>('fill-color').withDefault(element =>
    neutralLayer1.getValueFor(element)
);

export const neutralForegroundRest = create<Swatch>('neutral-foreground-rest').withDefault(element =>
    neutralForegroundRecipe.getValueFor(element).evaluate(element).rest
);

export const neutralForegroundHover = create<Swatch>('neutral-foreground-hover').withDefault(element =>
    neutralForegroundRecipe.getValueFor(element).evaluate(element).hover
);

export const neutralForegroundActive = create<Swatch>('neutral-foreground-active').withDefault(element =>
    neutralForegroundRecipe.getValueFor(element).evaluate(element).active
);

export const neutralForegroundFocus = create<Swatch>('neutral-foreground-focus').withDefault(element =>
    neutralForegroundRecipe.getValueFor(element).evaluate(element).focus
);

export const neutralFillRest = create<Swatch>('neutral-fill-rest').withDefault(element =>
    neutralFillRecipe.getValueFor(element).evaluate(element).rest
);

export const neutralFillHover = create<Swatch>('neutral-fill-hover').withDefault(element =>
    neutralFillRecipe.getValueFor(element).evaluate(element).hover
);

export const neutralFillActive = create<Swatch>('neutral-fill-active').withDefault(element =>
    neutralFillRecipe.getValueFor(element).evaluate(element).active
);

export const neutralInputFillRest = create<Swatch>('neutral-input-fill-rest').withDefault(element =>
    neutralInputFillRecipe.getValueFor(element).evaluate(element).rest
);

export const neutralInputFillHover = create<Swatch>('neutral-input-fill-hover').withDefault(element =>
    neutralInputFillRecipe.getValueFor(element).evaluate(element).hover
);

export const neutralInputFillActive = create<Swatch>('neutral-input-fill-active').withDefault(element =>
    neutralInputFillRecipe.getValueFor(element).evaluate(element).active
);

export const neutralInputFillFocus = create<Swatch>('neutral-input-fill-focus').withDefault(element =>
    neutralInputFillRecipe.getValueFor(element).evaluate(element).focus
);

// TODO: Recipe to base on current layer?
export const neutralStealthFillRest = create<string>('neutral-stealth-fill-rest').withDefault('transparent');

export const neutralStealthFillHover = create<Swatch>('neutral-stealth-fill-hover').withDefault(element =>
    neutralStealthFillRecipe.getValueFor(element).evaluate(element).hover
);

export const neutralStealthFillActive = create<Swatch>('neutral-stealth-fill-active').withDefault(element =>
    neutralStealthFillRecipe.getValueFor(element).evaluate(element).active
);

export const neutralStealthFillFocus = create<Swatch>('neutral-stealth-fill-focus').withDefault(element =>
    neutralStealthFillRecipe.getValueFor(element).evaluate(element).focus
);

export const neutralFillFocus = create<Swatch>('neutral-fill-focus').withDefault(element =>
    neutralFillRecipe.getValueFor(element).evaluate(element).focus
);

export const neutralStrokeRest = create<Swatch>('neutral-stroke-rest').withDefault(element =>
    neutralStrokeRecipe.getValueFor(element).evaluate(element).rest
);

export const neutralStrokeHover = create<Swatch>('neutral-stroke-hover').withDefault(element =>
    neutralStrokeRecipe.getValueFor(element).evaluate(element).hover
);

export const neutralStrokeActive = create<Swatch>('neutral-stroke-active').withDefault(element =>
    neutralStrokeRecipe.getValueFor(element).evaluate(element).active
);

export const neutralStrokeFocus = create<Swatch>('neutral-stroke-focus').withDefault(element =>
    neutralStrokeRecipe.getValueFor(element).evaluate(element).focus
);

export const neutralDivider = create<Swatch>('neutral-divider').withDefault(element =>
    neutralDividerRecipe.getValueFor(element).evaluate(element)
);

export const focusStrokeOuter = create<Swatch>('focus-stroke-outer').withDefault(element =>
    focusStrokeOuterRecipe.getValueFor(element).evaluate(element)
);

export const focusStrokeInner = create<Swatch>('focus-stroke-inner').withDefault(element =>
    focusStrokeInnerRecipe.getValueFor(element).evaluate(element)
);

export const accentFillRest = create<Swatch>('accent-fill-rest').withDefault(element =>
    accentFillRecipe.getValueFor(element).evaluate(element).rest
);

export const accentFillHover = create<Swatch>('accent-fill-hover').withDefault(element =>
    accentFillRecipe.getValueFor(element).evaluate(element).hover
);

export const accentFillActive = create<Swatch>('accent-fill-active').withDefault(element =>
    accentFillRecipe.getValueFor(element).evaluate(element).active
);

export const accentFillFocus = create<Swatch>('accent-fill-focus').withDefault(element =>
    accentFillRecipe.getValueFor(element).evaluate(element).focus
);

export const accentForegroundRest = create<Swatch>('accent-foreground-rest').withDefault(element =>
    accentForegroundRecipe.getValueFor(element).evaluate(element).rest
);

export const accentForegroundHover = create<Swatch>('accent-foreground-hover').withDefault(element =>
    accentForegroundRecipe.getValueFor(element).evaluate(element).hover
);

export const accentForegroundActive = create<Swatch>('accent-foreground-active').withDefault(element =>
    accentForegroundRecipe.getValueFor(element).evaluate(element).active
);

export const accentForegroundFocus = create<Swatch>('accent-foreground-focus').withDefault(element =>
    accentForegroundRecipe.getValueFor(element).evaluate(element).focus
);

export const foregroundOnAccentRest = create<Swatch>('foreground-on-accent-rest').withDefault(element =>
    calculateForegroundOnColor(accentFillRest.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnAccentHover = create<Swatch>('foreground-on-accent-hover').withDefault(element =>
    calculateForegroundOnColor(accentFillHover.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnAccentActive = create<Swatch>('foreground-on-accent-active').withDefault(element =>
    calculateForegroundOnColor(accentFillActive.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnAccentFocus = create<Swatch>('foreground-on-accent-focus').withDefault(element =>
    calculateForegroundOnColor(accentFillFocus.getValueFor(element), ContrastTarget.Normal)
);

export const secondaryFillRest = create<Swatch>('secondary-fill-rest').withDefault(element =>
    secondaryFillRecipe.getValueFor(element).evaluate(element).rest
);

export const secondaryFillHover = create<Swatch>('secondary-fill-hover').withDefault(element =>
    secondaryFillRecipe.getValueFor(element).evaluate(element).hover
);

export const secondaryFillActive = create<Swatch>('secondary-fill-active').withDefault(element =>
    secondaryFillRecipe.getValueFor(element).evaluate(element).active
);

export const secondaryFillFocus = create<Swatch>('secondary-fill-focus').withDefault(element =>
    secondaryFillRecipe.getValueFor(element).evaluate(element).focus
);

export const secondaryForegroundRest = create<Swatch>('secondary-foreground-rest').withDefault(element =>
    secondaryForegroundRecipe.getValueFor(element).evaluate(element).rest
);

export const secondaryForegroundHover = create<Swatch>('secondary-foreground-hover').withDefault(element =>
    secondaryForegroundRecipe.getValueFor(element).evaluate(element).hover
);

export const secondaryForegroundActive = create<Swatch>('secondary-foreground-active').withDefault(element =>
    secondaryForegroundRecipe.getValueFor(element).evaluate(element).active
);

export const secondaryForegroundFocus = create<Swatch>('secondary-foreground-focus').withDefault(element =>
    secondaryForegroundRecipe.getValueFor(element).evaluate(element).focus
);

export const foregroundOnSecondaryRest = create<Swatch>('foreground-on-secondary-rest').withDefault(element =>
    calculateForegroundOnColor(secondaryFillRest.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnSecondaryHover = create<Swatch>('foreground-on-secondary-hover').withDefault(element =>
    calculateForegroundOnColor(secondaryFillHover.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnSecondaryActive = create<Swatch>('foreground-on-secondary-active').withDefault(element =>
    calculateForegroundOnColor(secondaryFillActive.getValueFor(element), ContrastTarget.Normal)
);

export const foregroundOnSecondaryFocus = create<Swatch>('foreground-on-secondary-focus').withDefault(element =>
    calculateForegroundOnColor(secondaryFillFocus.getValueFor(element), ContrastTarget.Normal)
);

// Typography

export const direction = create<Direction>("direction").withDefault(Direction.ltr);
