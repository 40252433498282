import { css } from '@microsoft/fast-element';
import { disabledCursor, focusVisible } from '@microsoft/fast-foundation';
import { accentFillActive, accentFillHover, accentFillRest, fillColor, foregroundOnAccentRest, neutralFillFocus, neutralForegroundRest, neutralInputFillActive, neutralInputFillHover, neutralInputFillRest, neutralStrokeActive, neutralStrokeHover, neutralStrokeRest, designTokens } from '../../design-tokens';
import { heightNumber } from '../size';

export const baseRadioStyles = css`
    .control {
        position: relative;
        width: calc((${heightNumber} / 2 + ${designTokens['space-design-unit'].token}) * 1px);
        height: calc((${heightNumber} / 2 + ${designTokens['space-design-unit'].token}) * 1px);
        box-sizing: border-box;
        border-radius: 999px;
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${neutralStrokeRest};
        background: ${neutralInputFillRest};
        outline: none;
        cursor: pointer;
    }

    .label {
        font-family: ${designTokens['typography-font-family'].token};
        color: ${neutralForegroundRest};
        padding-inline-start: calc(${designTokens['space-design-unit'].token} * 2px + 2px);
        cursor: pointer;
        font-size: ${designTokens['typography-type-ramp-base-font-size'].token};
        line-height: ${designTokens['typography-type-ramp-base-line-height'].token};
    }

    .label__hidden {
        display: none;
        visibility: hidden;
    }

    .control, .checked-indicator {
        flex-shrink: 0;
    }

    .checked-indicator {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 999px;
        display: inline-block;
        fill: ${foregroundOnAccentRest};
        opacity: 0;
        pointer-events: none;
    }

    :host(:not([disabled])) .control:hover {
        background: ${neutralInputFillHover};
        border-color: ${neutralStrokeHover};
    }

    :host(:not([disabled])) .control:active {
        background: ${neutralInputFillActive};
        border-color: ${neutralStrokeActive};
    }

    :host(:${focusVisible}) .control {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${neutralFillFocus};
        border-color: ${neutralFillFocus};
    }

    :host(.checked) .control {
        background: ${accentFillRest};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillRest};
    }

    :host(.checked:not([disabled])) .control:hover {
        background: ${accentFillHover};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillHover};
    }

    :host(.checked:not([disabled])) .control:active {
        background: ${accentFillActive};
        border: calc(${designTokens['space-stroke-width'].token} * 1px) solid ${accentFillActive};
    }

    :host(.checked:${focusVisible}:not([disabled])) .control {
        box-shadow: 0 0 0 2px ${fillColor}, 0 0 0 4px ${neutralFillFocus};
        border-color: transparent;
    }

    :host(.disabled) .label,
    :host(.readonly) .label,
    :host(.readonly) .control,
    :host(.disabled) .control {
        cursor: ${disabledCursor};
    }

    :host(.checked) .checked-indicator {
        opacity: 1;
    }

    :host(.disabled) {
        opacity: ${designTokens['color-disabled-opacity'].token};
    }
`;
