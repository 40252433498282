import { accordionTemplate, FoundationElement } from '@microsoft/fast-foundation';
import { accordionStyles } from './accordion.styles';

/**
 * @element natgen-accordion
 */
export class NatGenAccordion extends FoundationElement { }

export const natGenAccordion = NatGenAccordion.compose({
    baseName: 'accordion',
    template: accordionTemplate,
    styles: accordionStyles
});
