import { html, slotted, ViewTemplate, when } from '@microsoft/fast-element';
import { FoundationElementTemplate } from '@microsoft/fast-foundation';
import { NatGenFormField } from './form-field';

// TODO: Should tooltips be projected to a container at the provider level?
// How to project tooltip attributes onto container?

export const formFieldTemplate: FoundationElementTemplate<ViewTemplate<NatGenFormField>> = (
    context,
    definition
) => html`
    <template>
        <div>
            <slot name="validation"></slot>
            <slot name="label"></slot>
            <natgen-button class="tooltip" appearance="lightweight" ?hidden="${x => x.slottedTooltipNodes?.length == 0}">
                <natgen-icon name="tooltip" size="5"></natgen-icon>
            </natgen-button>
            <br />
            <slot></slot>
        </div>

        <natgen-popper selector=".tooltip" offset="[0, 10]" placement="auto" flip>
            <natgen-card>
                <slot name="tooltip" ${slotted('slottedTooltipNodes')}></slot>
            </natgen-card>
        </natgen-popper>
    </template>
`;
