import { css, ElementStyles } from '@microsoft/fast-element';
import { display, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { neutralLayer1 } from '../../design-tokens';

export const sideBarStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('flex')}

    :host {
        display: flex;
        flex-direction: column;

        box-sizing: border-box;

        background: ${neutralLayer1};
    }

    :host(.floating) {
        position: absolute;
        top: 0;
    }

    :host(.left) {
        left: 0;
    }

    :host(.right) {
        right: 0;
    }
`;
