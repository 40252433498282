import { RadioGroup, radioGroupTemplate } from '@microsoft/fast-foundation';
import { radioGroupStyles } from './radio-group.styles';

/**
 * @element natgen-radio-group
 */
export class NatGenRadioGroup extends RadioGroup {

}

export const natGenRadioGroup = NatGenRadioGroup.compose({
    baseName: 'radio-group',
    template: radioGroupTemplate,
    styles: radioGroupStyles
});
