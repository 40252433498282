import { html, ViewTemplate } from "@microsoft/fast-element";
import { FoundationElementTemplate } from "@microsoft/fast-foundation";
import { NatGenPopper } from "./popper";

export const popperTemplate: FoundationElementTemplate<ViewTemplate<NatGenPopper>> = (
    context,
    definition
) => html`
    <slot></slot>
`;
