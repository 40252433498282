import { natGenAccordionItem } from './components/accordion-item/accordion-item';
import { natGenAccordion } from './components/accordion/accordion';
import { natGenAnchor } from './components/anchor/anchor';
import { natGenButton } from './components/button/button';
import { natGenCard } from './components/card/card';
import { natGenCheckbox } from './components/checkbox/checkbox';
import { natGenDesignSystemProvider } from './components/design-system-provider/design-system-provider';
import { natGenDialog } from './components/dialog/dialog';
import { natGenFlag } from './components/flag/flag';
import { natGenHamburgerButton } from './components/hamburger-button/hamburger-button';
import { natGenIcon } from './components/icon/icon';
import { natGenLoader } from './components/loader/loader';
import { natGenLogo } from './components/logo/logo';
import { natGenNavbar as natGenNavBar } from './components/nav-bar/nav-bar';
import { natGenOption } from './components/option/option';
import { natGenPalette } from './components/palette/palette';
import { natGenPopper } from './components/popper/popper';
import { natGenProgressBar } from './components/progress-bar/progress-bar';
import { natGenRadioCard } from './components/radio-card/radio-card';
import { natGenRadioGroup } from './components/radio-group/radio-group';
import { natGenRadio } from './components/radio/radio';
import { natGenSelect } from './components/select/select';
import { natGenSideBar } from './components/side-bar/side-bar';
import { natGenSwitch } from './components/switch/switch';
import { natGenTextField } from './components/text-field/text-field';
import { natGenContainerLayout } from './layouts/container/container';
import { natGenFormField } from './layouts/form-field/form-field';
import { natGenForm } from './layouts/form/form';
import { natGenStackLayout } from './layouts/stack/stack';
import { provideNatGenDesignSystem } from './natgen-design-system';

export const NatGenDesignSystem = provideNatGenDesignSystem().register(
    natGenDesignSystemProvider(),
    natGenAccordion(),
    natGenAccordionItem(),
    natGenAnchor(),
    natGenButton(),
    natGenCard(),
    natGenCheckbox(),
    natGenContainerLayout(),
    natGenDialog(),
    natGenFlag(),
    natGenForm(),
    natGenFormField(),
    natGenHamburgerButton(),
    natGenIcon(),
    natGenLoader(),
    natGenLogo(),
    natGenNavBar(),
    natGenOption(),
    natGenPalette(),
    natGenPopper(),
    natGenProgressBar(),
    natGenRadio(),
    natGenRadioCard(),
    natGenRadioGroup(),
    natGenSelect(),
    natGenSideBar(),
    natGenStackLayout(),
    natGenSwitch(),
    natGenTextField()
);

export * from './design-tokens';

// TODO: Better way to do this?
export * from './data/countries';
