import { css, ElementStyles } from "@microsoft/fast-element";
import { display, FoundationElementTemplate } from "@microsoft/fast-foundation";

export const popperStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
    ${display('none')}

    :host([show]) {
        display: block;
    }
`;
