import { attr, css, DOM, html, observable } from '@microsoft/fast-element';
import { DesignToken, DesignTokenChangeRecord, FoundationElement } from '@microsoft/fast-foundation';
import { designTokens } from '../../design-tokens';

/**
 * @element natgen-icon
 */
export class NatGenIcon extends FoundationElement {
    protected currentIconName: string;

    @observable
    public iconContent: string;

    @observable
    public viewBox: string;

    @attr
    name: string;
    nameChanged(previousValue: string, nextValue: string) {
        if(previousValue != nextValue) {
            if (previousValue && !previousValue.includes('/') && `icon-${previousValue}` in designTokens) {
                designTokens[`icon-${previousValue}`].token.unsubscribe(this, this);
            }

            DOM.queueUpdate(() => this.loadIcon());

            if(!nextValue.includes('/') && `icon-${nextValue}` in designTokens)
                designTokens[`icon-${nextValue}`].token.subscribe(this, this)
        }
    }

    handleChange(record: DesignTokenChangeRecord<DesignToken<any>>): void {
        if (record.token === designTokens[`icon-${this.name}`].token) {
            DOM.queueUpdate(() => this.loadIcon());
        }
    }

    loadIcon() {
        const iconName = !this.name.includes('/') && `icon-${this.name}` in designTokens ?
            designTokens[`icon-${this.name}`].getValueFor(this) :
            this.name;

        if(iconName != this.currentIconName)
        {
            this.currentIconName = iconName;

            // TODO: throw loaded event?
            fetch(`${process.env.ASSET_PATH ? process.env.ASSET_PATH : ''}icons/${iconName}.svg`)
                .then(r => r.text())
                .then(text => {
                    const parser = new DOMParser();
                    const svgElement = parser.parseFromString(text, 'image/svg+xml').querySelector('svg');
                    this.viewBox = svgElement.getAttribute('viewBox');
                    this.iconContent = svgElement.innerHTML;
                })
                .catch(reason => console.error(`Unknown icon ${this.name}`, reason));
        }
    }

    getHeight() {
        return this.size * designTokens['space-design-unit'].getValueFor(this);
    }

    getWidth() {
        const viewBoxSizes = this.viewBox?.split(' ').map(s => parseFloat(s));

        if(viewBoxSizes == null || viewBoxSizes.length != 4)
            return this.getHeight();

        return (this.getHeight() / viewBoxSizes[3]) * viewBoxSizes[2];
    }

    @attr
    size: number = 5;
}

export const natGenIcon = NatGenIcon.compose({
    baseName: 'icon',
    template: html<NatGenIcon>`
        <template style="height: calc(${x => x.getHeight()} * 1px); width: calc(${x => x.getWidth()} * 1px);">
            <svg viewBox="${x => x.viewBox}" :innerHTML="${x => x.iconContent}">
            </svg>
        </template>
    `,
    styles: css`
        :host {
            display: inline-flex;
        }

        :host > svg {
            fill: currentColor;
            width: 100%;
            height: 100%;
        }
    `
});
