import { radioCardStyles } from './radio-card.styles';
import { RadioOptions } from '@microsoft/fast-foundation';
import { NatGenRadio } from '../radio/radio';
import { radioCardTemplate } from './radio-card.template';

/**
 * @element natgen-radio-card
 */
export class NatGenRadioCard extends NatGenRadio {

}

export const natGenRadioCard = NatGenRadioCard.compose<RadioOptions>({
    baseName: 'radio-card',
    template: radioCardTemplate,
    styles: radioCardStyles
});
