import { flagTemplate } from './flag.template';
import { flagStyles } from './flag.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';

/**
 * @element natgen-flag
 */
export class NatGenFlag extends FoundationElement {
    @attr({ attribute: 'country-code' })
    public countryCode: string;
}

export const natGenFlag = NatGenFlag.compose({
    baseName: 'flag',
    template: flagTemplate,
    styles: flagStyles
});
