import { parseColorHexRGB } from "@microsoft/fast-colors";
import { ValueConverter } from "@microsoft/fast-element";
import { Swatch, SwatchRGB } from "../color";

/**
 * A {@link ValueConverter} that converts to and from `Swatch` values.
 * @remarks
 * This converter allows for colors represented as string hex values, returning `null` if the
 * input was `null` or `undefined`.
 * @internal
 */
 export const swatchConverter: ValueConverter = {
    toView(value: any): string | null {
        if (value === null || value === undefined) {
            return null;
        }
        return (value as Swatch)?.toColorString();
    },

    fromView(value: any): any {
        if (value === null || value === undefined) {
            return null;
        }
        const color = parseColorHexRGB(value);
        return color ? new SwatchRGB(color.r, color.g, color.b) : null;
    },
};
