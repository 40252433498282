import { formGlobalStyles, formStyles } from './form.styles';
import { FoundationElement } from '@microsoft/fast-foundation';
import { html } from '@microsoft/fast-element';
import { MediaSizeMixin } from '../../utilities/media-size-observer';

/**
 * @element natgen-form
 */
export class NatGenForm extends MediaSizeMixin(FoundationElement) {
    connectedCallback(): void {
        super.connectedCallback();

        // Due to limitations of the ::slotted selector some form styles need to live in the light DOM.
        if(!formGlobalStyles.isAttachedTo(document))
            formGlobalStyles.addStylesTo(document);
    }
}

export const natGenForm = NatGenForm.compose({
    baseName: 'form',
    template: html`<slot></slot>`,
    styles: formStyles
});
