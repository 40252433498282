import { css, ElementStyles } from '@microsoft/fast-element';
import { display, forcedColorsStylesheetBehavior, FoundationElementTemplate } from '@microsoft/fast-foundation';
import { SystemColors } from '@microsoft/fast-web-utilities';
import { neutralLayer1, designTokens } from '../../design-tokens';
import { elevationRule } from '../../styles/elevation';

export const cardStyles: FoundationElementTemplate<ElementStyles> = (
    context,
    definition
) => css`
        ${display('block')} :host {
            --elevation: 4;

            display: block;
            contain: content;
            height: var(--card-height, 100%);
            width: var(--card-width, 100%);
            box-sizing: border-box;
            background: ${neutralLayer1};
            border-radius: calc(${designTokens['space-corner-radius'].token} * 1px);
            ${elevationRule}
        }
    `.withBehaviors(
        forcedColorsStylesheetBehavior(
            css`
                :host {
                    background: ${SystemColors.Canvas};
                    color: ${SystemColors.CanvasText};
                    fill: currentcolor;
                }
            `
        )
    );
