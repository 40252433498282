import { attr, Notifier, observable, Observable } from '@microsoft/fast-element';
import { TextField, TextFieldOptions, TextFieldType } from '@microsoft/fast-foundation';
import dayjs from 'dayjs';
import IMask from "imask";
import { jsonConverter } from '../../value-converters/json-value-converter';
import { textFieldStyles } from './text-field.styles';
import { textFieldTemplate } from './text-field.template';

export type NatGenLabelAppearance = 'default' | 'material';
export type NatGenTextFieldAppearance = 'filled' | 'outline' | 'stealth';

function typeChangedHandler(source: NatGenTextField, propertyName: string) {
    if (propertyName === 'type' && source.type === TextFieldType.password && !source.isPasswordType)
        source.isPasswordType = true;
}

/**
 * @element natgen-text-field
 */
export class NatGenTextField extends TextField {
    /**
     * The appearance of the element.
     *
     * @public
     * @remarks
     * HTML Attribute: appearance
     */
    @attr
    public appearance: NatGenTextFieldAppearance;

    public appearanceChanged(
        oldValue: NatGenTextFieldAppearance,
        newValue: NatGenTextFieldAppearance
    ): void {
        if (oldValue !== newValue) {
            this.classList.add(newValue);
            this.classList.remove(oldValue);
        }
    }

    /**
     * The appearance the anchor should have.
     *
     * @public
     * @remarks
     * HTML Attribute: labelAppearance
     */
    @attr
    public labelAppearance: NatGenLabelAppearance;

    /**
     * The maximum value a user can enter.
     * @public
     * @remarks
     * HTMLAttribute: max
     */
    @attr
    public max: string;
    private maxChanged(): void {
        if (this.proxy instanceof HTMLElement) {
            this.proxy.max = this.max;
            this.validate();
        }
    }

    /**
     * The minimum value a user can enter.
     * @public
     * @remarks
     * HTMLAttribute: min
     */
    @attr
    public min: string;
    private minChanged(): void {
        if (this.proxy instanceof HTMLElement) {
            this.proxy.min = this.min;
            this.validate();
        }
    }

    @attr({ attribute: "disable-smart-punctuation", mode: "boolean" })
    public disableSmartPunctuation: boolean = false;
    private disableSmartPunctuationChanged() {
        if (this.disableSmartPunctuation && this.control?.value)
            this.control.value = this.cleanSmartPunctuation(this.control.value);
    }

    private cleanSmartPunctuation(value: string): string {
        return value.replace(/[\u2018\u2019]/g, "'") // single-quote variants
            .replace(/[\u201C\u201D]/g, '"') // double-quite variants
            .replace(/[\u2014]/g, '-'); // dash variants
    }

    protected maskInstance: IMask.AnyMasked;

    @attr
    public mask: string;

    @attr({ converter: jsonConverter, attribute: 'mask-settings' })
    public maskSettings: IMask.AnyMaskedOptions;

    protected updateMask(): void {
        if (!this.control)
            return;

        if (this.mask || this.maskSettings) {
            var maskSettings: any = this.maskSettings || { mask: this.mask };

            // TODO: Mask helpers should be broken out of text-field so they can be used in other components and for raw text. #JoshD
            if (this.mask.toLowerCase() == 'number')
                maskSettings.mask = Number;
            else if (this.mask.toLowerCase() == 'range')
                maskSettings.mask = IMask.MaskedRange;
            else if (this.mask.toLowerCase() == 'date') {
                maskSettings.mask = Date;
                maskSettings.pattern = maskSettings.pattern || 'MM/DD/YYYY'
                maskSettings.blocks = {
                    DD: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                        maxLength: 2,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        maxLength: 2,
                    },
                    YYYY: {
                        mask: IMask.MaskedRange,
                        from: 1900,
                        to: 9999,
                    }
                };

                maskSettings.format = (date: Date) => {
                    return dayjs(date).format(maskSettings.pattern);
                };

                maskSettings.parse = function (str) {
                    return dayjs(str, maskSettings.pattern);
                };
            } else if (this.mask.toLowerCase() == 'us-phone')
                maskSettings.mask = '(000) 000-0000';
            else if (this.mask.toLocaleLowerCase() == "credit-card")
            {
                // TODO: Credit Card mask with icons? #JoshD
            }

            else if (this.mask.toLowerCase() == 'zip-code')
                maskSettings.mask = '00000';

            else if (this.mask)
                maskSettings.mask = this.mask;

            this.maskInstance = IMask.createMask(maskSettings);
        } else {
            delete this.maskInstance;
        }
    }

    @observable isPasswordType = false;

    protected typeChangedNotifier: Notifier;

    togglePasswordVisibility() {
        if (this.type == TextFieldType.password)
            this.type = TextFieldType.text;
        else
            this.type = TextFieldType.password;
    }

    @attr({ attribute: 'auto-tab' })
    public autoTab: string;

    connectedCallback() {
        super.connectedCallback();

        if (!this.labelAppearance) {
            this.labelAppearance = "default";
        }

        this.isPasswordType = this.type === TextFieldType.password;

        this.typeChangedNotifier = Observable.getNotifier(this);
        this.typeChangedNotifier.subscribe({ handleChange: typeChangedHandler }, 'type');

        this.updateMask();
    }

    disconnectedCallback() {
        this.typeChangedNotifier.subscribe({ handleChange: typeChangedHandler }, 'type');
    }

    public handleTextInput(): void {
        if (this.disableSmartPunctuation) {
            const start = Math.max(this.control.selectionStart, 0);
            const end = Math.max(this.control.selectionEnd, 0);

            this.control.value = this.cleanSmartPunctuation(this.control.value);
            this.control.setSelectionRange(start, end);
        }

        if (this.maskInstance) {
            const newValue = this.maskInstance.resolve(this.control.value);
            const selectionChange = newValue.length - this.control.value.length;
            const start = Math.max(this.control.selectionStart + selectionChange, 0);
            const end = Math.max(this.control.selectionEnd + selectionChange, 0);

            this.control.value = newValue;
            this.control.setSelectionRange(start, end);
        }

        super.handleTextInput();

        if (this.autoTab && this.maxlength && this.value.length == this.maxlength) {
            (document.querySelector(this.autoTab) as HTMLElement)?.focus()
        }
    }
}

export const natGenTextField = NatGenTextField.compose<TextFieldOptions>({
    baseName: 'text-field',
    baseClass: TextField,
    template: textFieldTemplate,
    styles: textFieldStyles,
    shadowOptions: {
        delegatesFocus: true,
    }
});
