import { FoundationElement } from "@microsoft/fast-foundation";
import { progressBarStyles } from "./progress-bar.styles";
import { progressBarTemplate } from "./progress-bar.template";

/**
 * @element natgen-progress-bar
 */
export class NatGenProgressBar extends FoundationElement { }

export const natGenProgressBar = NatGenProgressBar.compose({
    baseName: 'progress-bar',
    template: progressBarTemplate,
    styles: progressBarStyles
});