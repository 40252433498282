import { css } from '@microsoft/fast-element';

export const typeRampStyles = css`
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: 700;
    }

    h1 {
        font-size: var(--typography-type-ramp-plus-6-font-size);
        line-height: var(--typography-type-ramp-plus-6-line-height);
    }

    h2 {
        font-size: var(--typography-type-ramp-plus-5-font-size);
        line-height: var(--typography-type-ramp-plus-5-line-height);
    }

    h3 {
        font-size: var(--typography-type-ramp-plus-4-font-size);
        line-height: var(--typography-type-ramp-plus-4-line-height);
    }

    h4 {
        font-size: var(--typography-type-ramp-plus-3-font-size);
        line-height: var(--typography-type-ramp-plus-3-line-height);
    }

    h5 {
        font-size: var(--typography-type-ramp-plus-2-font-size);
        line-height: var(--typography-type-ramp-plus-2-line-height);
    }

    h6 {
        font-size: var(--typography-type-ramp-plus-1-font-size);
        line-height: var(--typography-type-ramp-plus-1-line-height);
    }
`;
